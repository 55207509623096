import { Analytics, GTMData, OrderSummary } from "@types";
import { COUNTRY_CODE } from "./constants";
import { CheckoutDetails } from "@features/checkout/types";
import { AnalyticsTypeEnum } from "./enums";
import { currencyLookup } from "./helpers";

export const sendGTMEvent = (data: Object) => {
  // define dataLayer so we can still queue up events before GTM init
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export function getAnalyticsQueryParamsFromCheckoutDetails(
  cartList?: CheckoutDetails["CartList"]
) {
  try {
    const eventIds: number[] = [];

    if (cartList) {
      cartList.forEach((item) => {
        if (!eventIds.includes(item.EventId)) {
          eventIds.push(item.EventId);
        }
      });
    }

    const formattedEventIds = eventIds.join(",");

    return { formattedEventIds };
  } catch (e) {
    console.error(e);
  }
}

export function getAnalyticsQueryParamsFromOrderSummary(
  orderSummary?: OrderSummary
) {
  try {
    const eventIds: number[] = [];

    if (orderSummary && orderSummary.events.length > 0) {
      orderSummary.events.forEach((item) => {
        if (!eventIds.includes(item.id)) {
          eventIds.push(item.id);
        }
      });
    }

    const formattedEventIds = eventIds.join(",");

    return { formattedEventIds };
  } catch (e) {
    console.error(e);
  }
}

export function formatGTMCartEvent(
  cartItems: CheckoutDetails["CartList"],
  eventType: string
) {
  try {
    const totalPrice = cartItems.reduce((acc, item) => acc + item.SubTotal, 0);

    const items = cartItems.flatMap((item) => {
      return item.PriceAgeGroup.map((priceAge) => {
        return {
          item_id: item.EventId,
          sku: `${item.EventId}-${item.ShowingId}-${priceAge.PriceAgeId}`,
          item_name: item.EventName,
          item_variant: priceAge.Name,
          price: priceAge.IndividualPrice,
          quantity: priceAge.Count,
        };
      });
    });

    return {
      event: eventType,
      currency: currencyLookup[COUNTRY_CODE ?? "nz"].currency,
      value: totalPrice,
      items,
    };
  } catch (e) {
    console.error(e);
  }
}

export function formatGTMPurchaseItems(tickets: OrderSummary["tickets"]) {
  try {
    const totalPrice = tickets.reduce((acc, item) => acc + item.price, 0);

    const items = tickets.map((ticket) => ({
      item_id: ticket.event.id,
      sku: `${ticket.event.id}-${ticket.showing.id}-${ticket.priceage.id}`,
      item_name: ticket.event.name,
      item_variant: ticket.priceage.name,
      price: ticket.price,
      quantity: 1,
    }));

    return {
      value: totalPrice,
      items,
    };
  } catch (e) {
    console.error(e);
  }
}

export function sendGTMTrackingEvent(data: GTMData, config: Analytics[]) {
  try {
    if (config.length === 0) return;

    for (const configItem of config) {
      const tagConfig =
        configItem.type === AnalyticsTypeEnum.GA4 &&
        configItem.config.measurementId
          ? { ga4Id: configItem.config.measurementId }
          : configItem.type === AnalyticsTypeEnum.METAPIXEL &&
            configItem.config.metapixelId
          ? { metaPixelId: configItem.config.metapixelId }
          : null;

      if (tagConfig) {
        sendGTMEvent({
          ...data,
          tagConfig,
          _clear: true,
        });

        if (window?.dataLayer) {
          // purge measurement IDs so other tags don't pick it up
          window.dataLayer.push({
            tagConfig: {
              ga4Id: undefined,
              metaPixelId: undefined,
            },
            transaction_id: undefined,
          });
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function sendIticketGTMTrackingEvent(data: GTMData) {
  try {
    sendGTMEvent({
      ...data,
      tagConfig: {
        ga4Id: "iticket",
        metaPixelId: "iticket",
      },
      _clear: true,
    });

    if (window?.dataLayer) {
      // purge measurement IDs so other tags don't pick it up
      window.dataLayer.push({
        tagConfig: {
          ga4Id: undefined,
          metaPixelId: undefined,
        },
        transaction_id: undefined,
      });
    }
  } catch (e) {
    console.error(e);
  }
}

export function trackGTMCartEvent(
  events: CheckoutDetails["CartList"],
  analyticsConfigs: Analytics[],
  eventType: string
) {
  try {
    if (analyticsConfigs.length > 0) {
      const groupedByConfigId = analyticsConfigs.reduce((acc, item) => {
        if (item.id) {
          if (!acc[item.id]) {
            acc[item.id] = [];
          }
          acc[item.id].push(item);
        }
        return acc;
      }, {} as { [key: string]: Analytics[] });

      Object.keys(groupedByConfigId).forEach((configId) => {
        const eventIds = groupedByConfigId[configId].map(
          (item) => item.eventId
        );
        const eventsForConfig = events.filter((item) =>
          eventIds.includes(item.EventId)
        );
        const formattedData = formatGTMCartEvent(eventsForConfig, eventType);
        if (eventsForConfig.length > 0 && formattedData) {
          sendGTMTrackingEvent(formattedData, [groupedByConfigId[configId][0]]);
        }
      });
    }

    // send a tracking event to iTICKET's tracking for all items in the cart
    const formattedData = formatGTMCartEvent(events, eventType);
    if (formattedData) {
      sendIticketGTMTrackingEvent(formattedData);
    }
  } catch (e) {
    console.error(e);
  }
}

// Separate function due to data structure differences in cart and order summary
export function trackGTMPurchaseEvent(
  orderSummary: OrderSummary,
  analyticsConfigs: Analytics[],
  orderId: string
) {
  try {
    const baseData = {
      event: "purchase",
      transaction_id: orderId,
      currency: orderSummary.payment.currency.code,
    };

    const groupedByConfigId = analyticsConfigs.reduce((acc, item) => {
      if (item.id) {
        if (!acc[item.id]) {
          acc[item.id] = [];
        }
        acc[item.id].push(item);
      }
      return acc;
    }, {} as { [key: string]: Analytics[] });

    if (analyticsConfigs.length > 0) {
      Object.keys(groupedByConfigId).forEach((configId) => {
        const eventIds = groupedByConfigId[configId].map(
          (item) => item.eventId
        );
        const eventsForConfig = orderSummary.tickets.filter((item) =>
          eventIds.includes(item.event.id)
        );
        if (eventsForConfig.length > 0) {
          sendGTMTrackingEvent(
            {
              ...baseData,
              ...formatGTMPurchaseItems(eventsForConfig),
            },
            [groupedByConfigId[configId][0]]
          );
        }
      });
    }

    // send a tracking event to iTICKET's tracking for all items in the cart
    sendIticketGTMTrackingEvent({
      ...baseData,
      ...formatGTMPurchaseItems(orderSummary.tickets),
    });
  } catch (e) {
    console.error(e);
  }
}

export function formatCustomerIOCartEvent(
  checkoutDetails: CheckoutDetails,
  cartId?: string
) {
  try {
    const organisationUids: string[] = [];
    const eventIds: number[] = [];

    checkoutDetails.CartList.forEach((item) => {
      if (!organisationUids.includes(item.PromoterUid)) {
        organisationUids.push(item.PromoterUid);
      }
      if (!eventIds.includes(item.EventId)) {
        eventIds.push(item.EventId);
      }
    });

    const products = checkoutDetails.CartList.flatMap((item) => {
      return item.PriceAgeGroup.map((priceAge) => {
        return {
          product_id: item.EventId,
          sku: `${item.EventId}-${item.ShowingId}-${priceAge.PriceAgeId}`,
          name: item.EventName,
          variant: priceAge.Name,
          price: priceAge.IndividualPrice,
          quantity: priceAge.Count,
        };
      });
    });

    return {
      affiliation: "iTICKET",
      cart_id: cartId,
      currency: currencyLookup[COUNTRY_CODE ?? "nz"].currency,
      value: checkoutDetails.ItemPriceSum,
      products,
      organisationUids,
      eventIds,
      cio_eventIds: eventIds.join("-"),
      cio_organisationUids: organisationUids.join(" "),
    };
  } catch (e) {
    console.error(e);
  }
}

export function formatCustomerIOPurchaseEvent(orderSummary: OrderSummary) {
  try {
    const organisationUids: string[] = [];
    const eventIds: number[] = [];

    if (orderSummary.events && orderSummary.events.length > 0) {
      orderSummary.events.forEach((item) => {
        if (!organisationUids.includes(item.organisationUid)) {
          organisationUids.push(item.organisationUid);
        }
        if (!eventIds.includes(item.id)) {
          eventIds.push(item.id);
        }
      });
    }

    const products = orderSummary.tickets.map((ticket) => ({
      product_id: ticket.event.id,
      sku: `${ticket.event.id}-${ticket.showing.id}-${ticket.priceage.id}`,
      name: ticket.event.name,
      variant: ticket.priceage.name,
      price: ticket.price,
      quantity: 1,
    }));

    return {
      affiliation: "iTICKET",
      order_id: orderSummary.id,
      checkout_id: orderSummary.basketKey,
      total: orderSummary.amount,
      revenue: orderSummary.ticketTotal,
      currency: orderSummary.payment.currency.code,
      products,
      organisationUids,
      eventIds,
      cio_eventIds: eventIds.join("-"),
      cio_organisationUids: organisationUids.join(" "),
      orderUserId: orderSummary.user.id,
    };
  } catch (e) {
    console.error(e);
  }
}
