import { EventShowing } from "@types";
import { ShowingStatus } from "@utils/enums";
import { joinClassNames } from "@utils/helpers";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowDownIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import ShowingStatusToolTip from "@features/eventListMode/components/showingStatusToolTip";

interface Props {
  showings: EventShowing[];
  messages: {
    id: number;
    name: string;
    access: string;
    type: string;
    status: number;
    message: string;
  }[];
  handleSelectDate: (id: number | null) => void;
  bookedDateTickets: (id: number) => number | undefined;
}

const item = {
  visible: (index: number) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: index * 0.05,
    },
  }),
  hidden: (index: number) => ({
    opacity: 0,
    x: 100,
    transition: {
      delay: index * 0.05,
    },
  }),
};

export default function EventDatePicker({
  showings,
  handleSelectDate,
  bookedDateTickets,
  messages,
}: Props) {
  const [selected, setSelected] = useState<number | null>(null);
  const [seeMore, setSeeMore] = useState(false);
  const allUniquedateShowings = Object.values(
    showings?.reduce(
      (acc, obj) => ({
        ...acc,
        [format(parseISO(obj.date.local.start), `d MMM yyyy`)]: obj,
      }),
      {} as EventShowing[]
    )
  ).map((showing) => {
    let isClickable = false;

    // if there is AT LEAST one available showing status and a mix of other showing status then make it clickable

    // if there is NO available showing status and a mix of other showings make it clickable

    // if there IS NOT available showing status and THE REST OF The showing statuses are the same, then not clickable

    // Create a set to store unique status values
    const statusSet = new Set<{
      colour: string;
      icon: string;
      id: number;
      name: string;
      hot: number;
    }>();

    // Loop through all showings for the same date
    for (const s of showings) {
      if (
        s.active &&
        format(parseISO(s.date.local.start), `d MMM yyyy`) ===
          format(parseISO(showing.date.local.start), `d MMM yyyy`)
      ) {
        const status = s.status.id;

        if (status === ShowingStatus.AVAILABLE) {
          isClickable = true;
        }

        statusSet.add(s.status);
      }
    }

    // If there's at least one "AVAILABLE" status or a mix of other statuses, make it clickable
    if (isClickable || statusSet.size > 1) {
      isClickable = true;
    }

    return {
      id: showing.id,
      datename: format(parseISO(showing.date.local.start), `EEE d MMM yyyy`),
      day: format(parseISO(showing.date.local.start), `d`),
      displayDate: showing.date.display,
      shows: showings?.filter(
        (s) =>
          s.active &&
          format(parseISO(s.date.local.start), `d MMM yyyy`) ===
            format(parseISO(showing.date.local.start), `d MMM yyyy`)
      ).length,
      access: showing.access,
      status:
        showings
          ?.filter(
            (s) =>
              s.active &&
              format(parseISO(s.date.local.start), `d MMM yyyy`) ===
                format(parseISO(showing.date.local.start), `d MMM yyyy`)
          )
          .find((s) => s.status.id === ShowingStatus.AVAILABLE)?.status ||
        showing.status,
      isClickable,
    };
  });
  const uniquedateShowings =
    allUniquedateShowings.length <= 6 || seeMore
      ? allUniquedateShowings
      : allUniquedateShowings.slice(0, 6);

  const handleShowingStatus = (statusId: number) => {
    const buttonMessageShowing =
      messages
        ?.filter((msg) => msg.type === "button")
        .find((msg) => msg.status === statusId)?.message ?? "";

    const messageShowing =
      messages
        ?.filter((msg) => msg.type === "message")
        .find((msg) => msg.status === statusId)?.message ?? "";

    if (
      statusId === ShowingStatus.SOLDOUT ||
      statusId === ShowingStatus.ADMINSOLDOUT ||
      statusId === ShowingStatus.CANCELLED
    ) {
      return {
        bgColor: "bg-red-100",
        textColor: "text-red-600",
        title: buttonMessageShowing,
        descriptionMsg: messageShowing,
        selectable: false,
      };
    }

    if (
      statusId === ShowingStatus.POSTPONED ||
      statusId === ShowingStatus.NOTYETONSALE
    ) {
      return {
        bgColor: "bg-orange-100",
        textColor: "text-orange-600",
        title: buttonMessageShowing,
        descriptionMsg: messageShowing,
        selectable: false,
      };
    }

    return {
      title: buttonMessageShowing,
      descriptionMsg: messageShowing,
      bgColor: "bg-indigo-100",
      textColor: "text-indigo-600",
    };
  };

  return (
    <div
      data-testid="showingdate"
      role="list"
      className={joinClassNames(
        "relative z-10 mb-6 sm:mb-4 mr-4",
        selected || uniquedateShowings.length === 1
          ? "inline-block w-full sm:w-[272px] h-full"
          : "flex flex-col gap-4"
      )}
    >
      <AnimatePresence>
        <ul
          className={
            selected || uniquedateShowings.length === 1
              ? "inline"
              : "isolate grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3"
          }
        >
          {uniquedateShowings.map((showing, index) => (
            <motion.li
              initial={uniquedateShowings.length > 1 ? "hidden" : "visible"}
              animate="visible"
              exit="hidden"
              custom={index >= 6 ? index - 3 : index}
              variants={item}
              onClick={() => {
                if (!showing.isClickable) return;

                handleSelectDate(
                  selected === showing.id && uniquedateShowings.length > 1
                    ? null
                    : showing.id
                );
                setSelected(
                  selected === showing.id && uniquedateShowings.length > 1
                    ? null
                    : showing.id
                );
              }}
              whileTap={{
                scale: 0.95,
              }}
              key={showing.id}
              className={joinClassNames(
                selected && selected !== showing.id ? "hidden" : "flex",
                "relative flex-1 items-center justify-between rounded-md border shadow border-gray-100 transition-colors ease-in-out duration-200",
                selected === showing.id || uniquedateShowings.length === 1
                  ? "bg-indigo-50 ring-2 ring-indigo-500"
                  : showing.isClickable
                  ? "bg-white"
                  : "bg-gray-100 cursor-not-allowed",
                showing.isClickable && uniquedateShowings.length > 1
                  ? "cursor-pointer"
                  : ""
              )}
            >
              <div className="flex flex-1 px-4 py-2 justify-between items-center gap-1 text-base font-medium text-black">
                <span
                  className={joinClassNames(
                    "font-medium truncate",
                    showing.status.id !== ShowingStatus.AVAILABLE
                      ? "text-gray-600"
                      : "text-black"
                  )}
                >
                  {showing.datename}
                </span>
                {selected === showing.id && uniquedateShowings.length > 1 ? (
                  <div className="flex gap-1 items-center shrink-0">
                    <ArrowPathIcon className="h-4 w-4 text-gray-500" />
                    <span className="text-gray-500 text-sm">Change date</span>
                  </div>
                ) : showing.isClickable ? (
                  <p className="text-gray-500 text-sm shrink-0">
                    {showing.shows === 1
                      ? "1 show"
                      : showing.shows > 1
                      ? `${showing.shows} shows`
                      : ""}
                  </p>
                ) : (
                  <div
                    className={joinClassNames(
                      "flex items-center gap-1 p-1 rounded-lg w-fit text-sm shrink-0",
                      handleShowingStatus(showing.status.id).bgColor ?? ""
                    )}
                  >
                    <span
                      className={
                        handleShowingStatus(showing.status.id).textColor
                      }
                    >
                      {handleShowingStatus(showing.status.id).title}
                    </span>
                    <ShowingStatusToolTip
                      title={handleShowingStatus(showing.status.id).title}
                      description={
                        handleShowingStatus(showing.status.id).descriptionMsg
                      }
                      statusId={showing.status.id}
                      accessType={showing.access.type}
                    />
                  </div>
                )}
              </div>
              {bookedDateTickets(showing.id) ? (
                <span className="absolute -right-2 -top-4 block h-5 w-auto px-2 pt-0.5 rounded-lg bg-indigo-100  text-indigo-800 text-xs text-center">
                  {bookedDateTickets(showing.id)} in cart
                </span>
              ) : (
                <></>
              )}
            </motion.li>
          ))}
        </ul>
      </AnimatePresence>
      {allUniquedateShowings.length > 6 && !selected ? (
        <div className="flex flex-col gap-2">
          {!seeMore && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
              className="text-sm text-center text-gray-500"
            >
              {allUniquedateShowings.length - 6 === 1
                ? "There is 1 more date available"
                : `There are ${
                    allUniquedateShowings.length - 6
                  } more dates available`}
            </motion.p>
          )}
          <motion.div
            layout
            className="w-full flex items-center justify-center"
          >
            <button
              onClick={() => setSeeMore((prev) => !prev)}
              className="flex items-center gap-1 text-indigo-800 px-2 py-1 rounded-lg bg-indigo-50 hover:bg-indigo-100 transition-colors ease-in-out duration-200"
            >
              <ArrowDownIcon
                className={joinClassNames(
                  "w-4 h-4 transition-transform ease-in-out duration-200",
                  seeMore ? "rotate-180" : ""
                )}
              />
              <span>Show me {seeMore ? "less" : "more"}</span>
            </button>
          </motion.div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
