import React, { useState } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useWindowSize } from "@utils/hooks";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/popovers/dialog";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  title: string;
  description: string;
  icon?: React.ReactNode;
}

const EventToolTip = ({ title, description, icon }: Props) => {
  const windowSize = useWindowSize();
  const [open, setOpen] = useState(false);
  return windowSize.width && windowSize.width > 640 ? (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger
          aria-label={`${title}-button`}
          className="cursor-default"
        >
          <QuestionMarkCircleIcon className="w-5 h-5" />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="bottom"
            sideOffset={10}
            className="relative z-50 bg-indigo-100 w-96 p-4 text-indigo-800 rounded-lg animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          >
            <p className="text-xl font-bold">{title}</p>
            <p className="text-sm text-indigo-700">{description}</p>
            <Tooltip.Arrow className="fill-indigo-100" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  ) : (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <QuestionMarkCircleIcon className="w-5 h-5" />
      </DialogTrigger>
      <AnimatePresence mode="wait">
        {open && (
          <DialogContent
            asChild
            forceMount
            handleClose={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
            className="bottom-4 !m-2 !rounded-3xl !p-0 !bg-white !max-w-[90dvw] max-h-[95dvh] !text-indigo-800 !border-none"
          >
            <motion.div
              initial="hidden"
              animate="show"
              exit="hidden"
              className="flex flex-col"
              transition={{ duration: 0.2 }}
              variants={{
                hidden: {
                  y: 200,
                  opacity: 0,
                },
                show: {
                  y: 0,
                  opacity: 1,
                },
              }}
            >
              <div className="relative px-6 pt-6 pb-4 flex flex-col gap-4 justify-between flex-1 bg-indigo-500 rounded-t-3xl">
                <div className="relative self-end w-8 h-8 rounded-full bg-white/10">
                  <DialogClose
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="absolute top-2 left-2 w-4 h-4 text-white !font-bold"
                  >
                    <XMarkIcon />
                  </DialogClose>
                </div>
                <DialogHeader className="!text-left">
                  <DialogTitle className="!text-white text-xl !font-bold">
                    {title}
                  </DialogTitle>
                  {icon ?? (
                    <QuestionMarkCircleIcon className="absolute top-0 left-4 w-24 h-24 text-white/10 -rotate-12" />
                  )}
                </DialogHeader>
              </div>
              <div className="px-6 text-gray-600">{description}</div>
              <div className="flex-1 flex items-center justify-center px-6 pb-6">
                <DialogClose
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="flex items-center justify-center w-full p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 text-white font-semibold"
                >
                  Done
                </DialogClose>
              </div>
            </motion.div>
          </DialogContent>
        )}
      </AnimatePresence>
    </Dialog>
  );
};

export default EventToolTip;
