import * as Popover from "@radix-ui/react-popover";
import { Slider } from "@components/inputs";
import {
  ArrowTrendingUpIcon,
  PencilSquareIcon,
  ShoppingCartIcon,
} from "@heroicons/react/20/solid";
import { PriceStatus } from "@utils/enums";
import { getCurrencyFormat } from "@utils/helpers";
import { AnimatePresence, motion } from "framer-motion";
import { Dispatch, SetStateAction, useState } from "react";
import { FormattedPrice } from "../types";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "@components/loadingSpinner";

interface Props {
  price: FormattedPrice;
  selectedQuantities: {
    id: string;
    qty: number;
    qty_from_combo: number;
  }[];
  dynamicPrice: number;
  setDynamicPrice: Dispatch<SetStateAction<number>>;
  putQtyInCart: (
    id: string,
    type: string,
    priceAgeId: number | null,
    priceSectionId: number | null,
    priceId: number | null,
    gaAreaId: number | null,
    qty: string,
    isCombo: boolean,
    price: number,
    comboId: number | null,
    isDynamic?: boolean
  ) => Promise<"success" | "failure">;
}

export default function FlexiPricePopover({
  price,
  selectedQuantities,
  dynamicPrice,
  setDynamicPrice,
  putQtyInCart,
}: Props) {
  const [open, setOpen] = useState(false);
  const [qty, setQty] = useState(0);
  const [loading, setLoading] = useState(false);

  const selectedQty = selectedQuantities.find((sq) => sq.id === price.id);

  const option_array = [
    0,
    ...Array.from(
      {
        length:
          (price.bookingLimit ?? price.max) -
          price.min +
          (price.min === 0 ? 0 : 1) +
          (price.bookingLimit && price.bookingLimit < price.max && selectedQty
            ? selectedQty.qty
            : 0),
      },
      (_, index) => price.min + index + (price.min === 0 ? 1 : 0)
    ),
  ];

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <button
          disabled={
            price.status !== PriceStatus.AVAILABLE && selectedQty?.qty === 0
          }
          className="bg-iticket text-white px-2 font-semibold disabled:bg-iticket/70 disabled:text-gray-100/70 disabled:cursor-not-allowed tabular-nums overflow-hidden inline-flex h-8 items-center rounded-md gap-1"
        >
          <ArrowTrendingUpIcon className="h-5 w-5" />
          {getCurrencyFormat(dynamicPrice ? dynamicPrice : price.price)}
          <PencilSquareIcon className="h-4 w-4" />
        </button>
      </Popover.Trigger>
      <Popover.Anchor />
      <AnimatePresence>
        {open && (
          <Popover.Portal forceMount>
            <Popover.Content
              forceMount
              sideOffset={5}
              align="end"
              alignOffset={-42}
              className="relative flex flex-col rounded-2xl w-72 z-10"
            >
              <motion.div
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={{
                  hidden: {
                    opacity: 0,
                    y: "-1rem",
                    transition: { duration: 0.125 },
                  },
                  show: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.25 }}
              >
                <div className="relative px-6 pt-8 pb-4 flex flex-col gap-4 justify-between flex-1 bg-iticket rounded-t-2xl border-b-0 border border-[#be003f]">
                  <span className="font-bold text-white text-xl">
                    FLEXi Price
                  </span>
                  <ArrowTrendingUpIcon className="absolute -top-0.5 left-2 w-14 h-14 text-white/10 -rotate-3" />
                </div>
                <div className="flex flex-col gap-2 pt-6 p-4 border-t-0 border rounded-b-2xl bg-white">
                  <div className="flex flex-wrap gap-2 items-center justify-center">
                    <div className="relative">
                      <select
                        value={qty}
                        onChange={(e) => setQty(parseInt(e.target.value))}
                        className="relative pl-2 pr-6 text-sm leading-none text-black border rounded shadow-sm cursor-default disabled:cursor-not-allowed w-fit h-9 focus:outline-none focus:ring-1 focus:ring-iticket bg-white border-gray-300 hover:bg-iticket/10 disabled:bg-gray-200 disabled:text-gray-500 focus:border-iticket transition-colors ease-in-out duration-200 appearance-none"
                      >
                        {option_array.map((num) => (
                          <option key={num} value={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                      <ChevronDownIcon className="absolute top-1/2 -translate-y-1/2 right-1 h-4 w-4 pointer-events-none" />
                    </div>
                    <p>{qty === 1 ? "ticket" : "tickets"} for</p>
                  </div>
                  <div className="flex items-center justify-center my-4 text-5xl font-black text-iticket">
                    <span className="relative w-fit">
                      {getCurrencyFormat(
                        dynamicPrice ? dynamicPrice : price.price
                      )}
                      <span className="absolute top-0 left-full text-base">
                        ea
                      </span>
                    </span>
                  </div>
                  <Slider
                    defaultValue={[dynamicPrice ? dynamicPrice : price.price]}
                    max={price.priceMaximum}
                    min={price.priceMinimum}
                    step={1}
                    onValueChange={(val) => {
                      setDynamicPrice(val[0]);
                    }}
                  />
                  <div className="flex items-center justify-between">
                    <span>{getCurrencyFormat(price.priceMinimum)}</span>
                    <span>{getCurrencyFormat(price.priceMaximum)}</span>
                  </div>
                  <button
                    onClick={async () => {
                      setLoading(true);
                      await putQtyInCart(
                        price.id,
                        price.type,
                        price.priceAgeId,
                        price.priceSectionId,
                        price.priceId,
                        price.gaAreaId,
                        qty.toString(),
                        price.isCombo,
                        dynamicPrice || price.price,
                        price.comboId ?? 0,
                        true
                      );
                      setQty(0);
                      setLoading(false);
                      setOpen(false);
                    }}
                    disabled={loading || qty === 0}
                    className="flex items-center justify-center gap-2 w-full p-2 rounded-md bg-iticket hover:bg-iticket/90 disabled:bg-iticket/60 text-white font-semibold transition-colors duration-200 ease-in-out"
                  >
                    {loading ? (
                      <>
                        <LoadingSpinner size="20" color="#ffffff" />
                        <span>Adding to cart...</span>
                      </>
                    ) : (
                      <>
                        <ShoppingCartIcon className="w-5 h-5" />
                        <span>Add to cart</span>
                      </>
                    )}
                  </button>
                </div>
              </motion.div>
            </Popover.Content>
          </Popover.Portal>
        )}
      </AnimatePresence>
    </Popover.Root>
  );
}
