import LoadingSpinner from "@components/loadingSpinner";
import { EventShowingsMsgs } from "@types";
import { joinClassNames } from "@utils/helpers";
import { useInterval } from "@utils/hooks";
import { useState } from "react";
import { KeyedMutator } from "swr";

interface Props {
  ttl: number;
  title: string;
  textColor?: string;
  loadingColor?: string;
  mutate: KeyedMutator<EventShowingsMsgs>;
}

const ShowingCountDown = ({
  ttl,
  title,
  textColor = "text-red-600",
  loadingColor = "#dc2626",
  mutate,
}: Props) => {
  const [count, setCount] = useState(ttl);

  useInterval(
    async () => {
      if (count > 0) {
        setCount(count - 1);
      }

      if (count === 0) {
        setCount(-1);
        await mutate();
      }
    },
    count > 0 ? 1000 : Infinity
  );

  const weeks = Math.floor(count / (60 * 60 * 24 * 7));
  const days = Math.floor((count % (60 * 60 * 24 * 7)) / (60 * 60 * 24));
  const hours = Math.floor((count % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((count % (60 * 60)) / 60);
  const seconds = count % 60;

  let formattedTime = "";

  // five minutes show a live countdown
  // less than 10 seconds
  if (
    weeks === 0 &&
    days === 0 &&
    hours === 0 &&
    (minutes < 5 || (minutes === 5 && seconds === 0))
  ) {
    formattedTime = `On sale in ${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  } else {
    formattedTime = title;
  }

  if (
    (weeks === 0 &&
      days === 0 &&
      hours === 0 &&
      minutes === 0 &&
      seconds <= 15) ||
    count < 0
  ) {
    return (
      <div className={`flex items-center gap-2 ${textColor}`}>
        <LoadingSpinner
          size="16"
          strokeWidth="4"
          color={
            textColor.includes("red")
              ? "#dc2626"
              : textColor.includes("orange")
              ? "#ea580c"
              : textColor.includes("green")
              ? "#16a34a"
              : loadingColor
          }
        />
        <span>Opening soon...</span>
      </div>
    );
  }

  return (
    <span
      className={joinClassNames(
        `${textColor} tabular-nums`,
        formattedTime === title ? "text-xs" : "text-sm"
      )}
    >
      {formattedTime}
    </span>
  );
};

export default ShowingCountDown;
