"use client";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@components/accordion";
import EventListMode from "@features/eventListMode";
import {
  Discount,
  Edgeconfig,
  EventData,
  Pricesection,
  PricesectionPriceage,
} from "@types";
import { EventMode } from "@utils/enums";
import { getCurrencyFormat, joinClassNames } from "@utils/helpers";
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import { Fragment, useState } from "react";
import {
  ExclamationTriangleIcon,
  FaceFrownIcon,
} from "@heroicons/react/24/solid";
import SearchResults from "@components/searchResults";
import EventCalendarMode from "@features/eventCalendarMode";
import { EventInfo } from ".";
import PriceSection from "./components/priceSection";
import { useWindowSize } from "@utils/hooks";
import * as Portal from "@radix-ui/react-portal";

interface Props {
  event: EventData;
  edgeConfig: Edgeconfig | undefined;
}

export default function EventDetails({ event, edgeConfig }: Props) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { scrollYProgress } = useScroll();
  const viewMode = searchParams.get("view");
  const promo = searchParams.get("promo");
  const windowSize = useWindowSize();
  const [accordionValue, setAccordionValue] = useState<string[]>([]);
  const [showMobileGetTickets, setShowMobileGetTickets] = useState(true);

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    const body = document.body,
      html = document.documentElement;

    const root_height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const max_latest = (root_height - 300) / root_height;
    setShowMobileGetTickets(latest < max_latest);
  });

  const showEventDescription = viewMode?.includes("info");

  const priceRange = (ids?: {
    pricesectionIds?: number[];
    groupedPricesectionIds?: number[];
  }) => {
    const { pricesectionIds, groupedPricesectionIds } = ids || {};
    const prices: number[][] = [];

    function processPriceItem(priceItem: number) {
      if (priceItem === 0) return;

      if (!prices[Math.floor(priceItem)]) {
        prices[Math.floor(priceItem)] = [];
      }

      if (!prices[Math.floor(priceItem)].includes(priceItem)) {
        prices[Math.floor(priceItem)][
          parseInt(priceItem.toFixed(2).split(".")[1])
        ] = priceItem;
      }
    }

    function processPriceage(priceage: PricesectionPriceage) {
      if (priceage.active && priceage.type === "standard") {
        processPriceItem(priceage.price.price);
      }
    }

    function processPricesection(pricesection: Pricesection) {
      if (!pricesection.active) return;

      // if array of pricesection ids is given, we are getting the price range of the "extra add ons"
      if (pricesectionIds && pricesectionIds.length > 0) {
        pricesection.priceages?.forEach((priceage) => {
          if (priceage.active && priceage.type === "extra") {
            processPriceItem(priceage.price.price);
          }
        });
        return;
      }
      if (groupedPricesectionIds) {
        if (groupedPricesectionIds.includes(pricesection.id)) {
          pricesection.manualprices
            ?.filter(
              (manualprice) =>
                manualprice.active &&
                !manualprice.isExtra &&
                manualprice.showInPriceRange
            )
            .forEach((manualprice) => {
              processPriceItem(manualprice.price);
            });

          const standard_priceages = pricesection.priceages
            ? pricesection.priceages.filter(
                (priceage) => priceage.active && priceage.type === "standard"
              )
            : [];

          if (standard_priceages.length === 0) {
            pricesection.priceages
              ?.filter(
                (priceage) => priceage.active && priceage.type === "extra"
              )
              .forEach((priceage) => processPriceItem(priceage.price.price));
          }

          standard_priceages.forEach((priceage) => {
            processPriceItem(priceage.price.price);
          });
        }

        return;
      }
      if (!groupedPricesectionIds && !pricesectionIds) {
        pricesection.manualprices
          ?.filter(
            (manualprice) => manualprice.active && manualprice.showInPriceRange
          )
          .forEach((manualprice) => {
            processPriceItem(manualprice.price);
          });

        pricesection.priceages?.forEach((priceage) => {
          processPriceage(priceage);
        });

        return;
      }
    }

    event.pricing.priceschemes?.forEach((pricescheme) => {
      if (pricescheme.pricesections && pricescheme.active) {
        pricescheme.pricesections.forEach((pricesection) => {
          processPricesection(pricesection);
        });
      }
    });

    if (prices.length === 0) {
      return { min: 0, max: 0, isEmpty: true };
    }

    const max = prices[prices.length - 1][prices[prices.length - 1].length - 1];
    const min_array = prices.find((dollar) => dollar);
    const min = min_array?.find((cent) => cent !== undefined) ?? max;

    return { min, max };
  };

  const filterDiscountDups = (discounts: Discount[]) => {
    const uniqueDiscounts: Discount[] = [];
    const discountNames: Set<string> = new Set();

    for (const discount of discounts) {
      if (!discountNames.has(discount.name)) {
        discountNames.add(discount.name);
        uniqueDiscounts.push(discount);
      }
    }

    return uniqueDiscounts;
  };

  const description = event.description.replaceAll(
    `href="https://www.iticket.co.nz`,
    `href="`
  );

  return (
    <div id="event_details_container">
      <div className="flex flex-col md:flex-row items-center gap-1 md:gap-4">
        {event.status === "past" ? (
          <div className="relative flex flex-col gap-1 p-2 rounded-lg bg-orange-100 text-orange-700 ring-2 ring-orange-600 sm:w-fit mt-4">
            <div className="flex gap-2 items-center">
              <ExclamationTriangleIcon className="hidden sm:block h-5 w-5" />
              <h4 className="text-xl font-black uppercase">
                Ticket Sales are no longer available
              </h4>
            </div>
            <span className="relative z-10">
              This event has been and gone and ticket sales are no longer
              available. Looking for a similar event? Search for what
              you&apos;re after by clicking{" "}
              <SearchResults
                icon={<span className="font-bold underline">here.</span>}
              />
            </span>
            <FaceFrownIcon className="absolute -bottom-2 -right-2 sm:top-0 sm:right-[4.25rem] text-orange-200 w-24 h-24 rotate-[15deg]" />
          </div>
        ) : edgeConfig?.eventPageSales.disabledByOrganisation.includes(
            event.organisation.uid
          ) ? (
          <span className="p-2 rounded-lg bg-orange-100 text-orange-700 ring-2 ring-orange-600 w-full mt-4">
            Please{" "}
            <span className="sm:hidden">
              click{" "}
              <a
                target="_blank"
                href={`https://www.iticket.co.nz${event.slug}`}
                className="font-bold underline"
              >
                here
              </a>
            </span>
            <span className="max-sm:hidden">
              visit{" "}
              <a
                target="_blank"
                href={`https://www.iticket.co.nz${event.slug}`}
                className="font-bold underline"
              >
                www.iticket.co.nz{event.slug}
              </a>
            </span>{" "}
            for more info and ticket purchasing options as sales for this event
            are not yet enabled for purchase via this beta website.
          </span>
        ) : (
          <>
            {windowSize.width &&
            windowSize.width <= 640 &&
            showEventDescription ? (
              <Portal.Root>
                <div
                  className={joinClassNames(
                    "fixed z-[48] bottom-0 inset-x-0 p-4 bg-white rounded-t-2xl border border-gray-200 shadow w-full transition-opacity duration-300 ease-in-out",
                    showMobileGetTickets ? "opacity-100" : "opacity-0"
                  )}
                >
                  <Link
                    data-testid="eventticketbutton"
                    href={
                      showEventDescription
                        ? `${pathname}${promo ? `?promo=${promo}` : ""}`
                        : `${pathname}?${
                            promo ? `promo=${promo}&` : ""
                          }view=info`
                    }
                    className="flex gap-1 w-full md:w-2/3 items-center justify-center p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 text-white font-semibold transition-colors duration-200 ease-in-out"
                  >
                    <span>Get Tickets</span>
                    <span>{"-"}</span>
                    {event.info.pricerange ? (
                      <span>{event.info.pricerange}</span>
                    ) : (
                      <span>
                        {priceRange().min !== priceRange().max ? (
                          <>{getCurrencyFormat(priceRange().min)} - </>
                        ) : (
                          <></>
                        )}
                        {getCurrencyFormat(priceRange().max)} + BF
                      </span>
                    )}
                  </Link>
                </div>
              </Portal.Root>
            ) : (
              <>
                <Link
                  data-testid="eventticketbutton"
                  href={
                    showEventDescription
                      ? `${pathname}${promo ? `?promo=${promo}` : ""}`
                      : `${pathname}?${promo ? `promo=${promo}&` : ""}view=info`
                  }
                  className={joinClassNames(
                    "flex w-full md:w-2/3 items-center justify-center p-2 rounded-md bg-indigo-500 hover:bg-indigo-400 disabled:bg-indigo-200 text-white font-semibold transition-colors duration-200 ease-in-out mt-8",
                    showEventDescription ? "max-sm:hidden" : ""
                  )}
                >
                  {showEventDescription ? `Get Tickets` : `View Event Details`}
                </Link>
                <div
                  className={joinClassNames(
                    "flex items-center gap-2 md:w-1/3 md:mt-8",
                    showEventDescription ? "max-sm:hidden" : ""
                  )}
                >
                  <h2 className="text-base leading-7 col-span-3 md:col-span-1 text-center md:text-left text-gray-500">
                    {event.info.pricerange ? (
                      event.info.pricerange
                    ) : (
                      <>
                        {priceRange().min !== priceRange().max ? (
                          <>{getCurrencyFormat(priceRange().min)} - </>
                        ) : (
                          <></>
                        )}
                        {getCurrencyFormat(priceRange().max)} + BF
                      </>
                    )}
                  </h2>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {event.subdescription ? (
        <div className="flex gap-1 items-start px-[0.625rem] pt-[0.625rem] mt-4 lg:mt-6 lg:-mb-4 rounded-lg bg-yellow-100 text-yellow-700 ring-2 ring-yellow-600">
          <ExclamationTriangleIcon className="h-7 w-7 p-1 shrink-0" />
          <div
            className="prose max-w-full text-yellow-700 prose-strong:text-yellow-700"
            dangerouslySetInnerHTML={{
              __html: event.subdescription,
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <div
        className={joinClassNames(
          "lg:hidden",
          event.subdescription ? "mt-4" : "sm:mt-10"
        )}
      >
        <EventInfo event={event} />
      </div>
      {!showEventDescription &&
      !edgeConfig?.eventPageSales.disabledByOrganisation.includes(
        event.organisation.uid
      ) &&
      event.status !== "past" ? (
        <AnimatePresence>
          {event.settings.showingdisplaytype === EventMode.LIST ? (
            <EventListMode event={event} getPriceRange={priceRange} />
          ) : (
            <EventCalendarMode event={event} getPriceRange={priceRange} />
          )}
        </AnimatePresence>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="my-6 space-y-6 divide-y divide-gray-900/10">
            <Accordion
              style={{
                scrollMarginTop: "6.5rem",
              }}
              type="multiple"
              value={accordionValue}
              onValueChange={setAccordionValue}
              className="overflow-hidden mt-10 border bg-white shadow-sm rounded-xl"
            >
              {event.payment.length > 0 ? (
                <AccordionItem value="Payment" className="!border-b-0">
                  <AccordionTrigger className="relative group flex w-full justify-between gap-x-6 px-4 py-5 sm:enabled:hover:bg-gray-50 sm:px-6">
                    <span className="text-black text-lg font-semibold leading-7">
                      Pricing information
                    </span>
                  </AccordionTrigger>
                  <AccordionContent className="p-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 items-start">
                      {event.pricing.priceschemes?.map((pricescheme, index) => (
                        <Fragment key={`${pricescheme.id}-${index}`}>
                          {pricescheme.active
                            ? pricescheme.pricesections?.map((pricesection) =>
                                pricesection.active ? (
                                  <PriceSection
                                    key={pricesection.id}
                                    pricesection={pricesection}
                                    priceschemeName={pricescheme.name}
                                  />
                                ) : null
                              )
                            : null}
                        </Fragment>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ) : (
                <></>
              )}
              {event.discount.filter(
                (discount) => discount.type.name !== "Combo" && discount.active
              ).length > 0 ? (
                <AccordionItem
                  value="Discount"
                  className={joinClassNames(
                    "!border-b-0",
                    event.payment.length > 0 ? "!border-t" : ""
                  )}
                >
                  <AccordionTrigger className="relative group flex w-full justify-between gap-x-6 px-4 py-5 sm:enabled:hover:bg-gray-50 sm:px-6">
                    <span className="text-black text-lg font-semibold leading-7">
                      {event.discount.filter(
                        (discount) =>
                          discount.type.name !== "Combo" && discount.active
                      ).length > 1
                        ? "Discounts"
                        : "Discount"}
                    </span>
                  </AccordionTrigger>
                  <AccordionContent className="p-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 items-start">
                      {filterDiscountDups(
                        event.discount.filter(
                          (discount) =>
                            discount.type.name !== "Combo" && discount.active
                        )
                      ).map((discount) => (
                        <div
                          key={discount.id}
                          className="flex gap-y-2 flex-col bg-indigo-100 rounded-2xl p-4"
                        >
                          <span className="text-xl text-indigo-600 font-extrabold">
                            {discount.name}
                          </span>
                          {discount.description ? (
                            <span className="text-sm leading-5 text-indigo-500">
                              {discount.description}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ) : (
                <></>
              )}
            </Accordion>
          </div>
          <div
            data-testid="eventdescription"
            className="text-black prose max-w-full text-sm prose-table:border-collapse prose-tr:border-none prose-img:inline prose-img:align-middle"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </motion.div>
      )}
    </div>
  );
}
