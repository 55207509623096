import { Skeleton } from "@components/wrapper";
import { Basket } from "@features/cartDetails/types";
import { EventData, EventShowingsMsgs } from "@types";
import { fetcher } from "@utils/fetchers";
import { useLatestCookieValue } from "@utils/hooks";
import { useSearchParams } from "next/navigation";
import useSWR from "swr";
import Showings from "./components/showings";

interface Props {
  getPriceRange: ({
    pricesectionIds,
    groupedPricesectionIds,
  }: {
    pricesectionIds?: number[];
    groupedPricesectionIds?: number[];
  }) => { min: number; max: number; isEmpty?: boolean };
  event: EventData;
}

export default function EventCalendarMode({ getPriceRange, event }: Props) {
  const basketKey = useLatestCookieValue("ASP.NET_SessionId");
  const params = useSearchParams();
  const promo = params.get("promo");
  const eventId = event.legacy.e_id;
  const eventVenueId = event.legacy.ev_id;

  const {
    data: showingData,
    isLoading,
    mutate,
  } = useSWR<EventShowingsMsgs>(
    event.status === "published"
      ? `events/${eventId}/${eventVenueId}/showings${
          promo ? `?promoCode=${promo}` : ""
        }`
      : null,
    fetcher
  );

  const { data: basket } = useSWR<Basket>(
    basketKey ? `basket/${basketKey}` : null,
    fetcher
  );

  return showingData && showingData.showings.length > 0 ? (
    <Showings
      key={showingData.showings.map((showing) => showing.access.type).join("-")}
      showingData={showingData}
      basket={basket}
      event={event}
      getPriceRange={getPriceRange}
      mutate={mutate}
    />
  ) : isLoading ? (
    <div className="mt-10">
      <Skeleton className="h-9 w-36 bg-gray-500 rounded-md" />
      <div>
        <div className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {Array.from({ length: 6 }).map((_, index) => (
            <Skeleton key={index} className="rounded-md h-10 w-full" />
          ))}
        </div>
      </div>
    </div>
  ) : showingData && showingData.showings.length === 0 ? (
    <div className="w-full flex flex-col items-center gap-4 p-4 sm:py-8">
      <h2 className="text-3xl font-bold tracking-tight text-black">
        There are currently no available dates with tickets
      </h2>
      <p className="text-lg text-black">Please try again shortly.</p>
    </div>
  ) : (
    <div className="w-full flex flex-col items-center gap-4 p-4 sm:py-8">
      <h2 className="text-3xl font-bold tracking-tight text-black">
        Dates and times not currently available.
      </h2>
      <p className="text-lg text-black">Please try again shortly.</p>
    </div>
  );
}
