import React, { useState } from "react";
import { FormattedPrice } from "../types";
import {
  getCookieValue,
  getCurrencyFormat,
  joinClassNames,
} from "@utils/helpers";
import {
  sendIticketGTMTrackingEvent,
  sendGTMTrackingEvent,
} from "@utils/analyticsUtils";
import { PriceStatus } from "@utils/enums";
import FlexiPricePopover from "./flexiPricePopover";
import AddTicketDropdown from "./addTicketDropdown";
import { useNotificationStore } from "@store/notificationStore";
import axios from "axios";
import { mutate } from "swr";
import { TicketItem } from "@features/cartDetails/types";
import MobileTicketDisplay from "./mobileTicketDisplay";
import { BASE_URL, COUNTRY_CODE } from "@utils/constants";
import { motion } from "framer-motion";
import { SparklesIcon } from "@heroicons/react/20/solid";
import { analytics } from "@components/analytics";
import { currencyLookup } from "@utils/helpers";
import { Analytics } from "@types";

interface Props {
  prices: FormattedPrice[];
  eventId: number;
  eventName: string;
  eventVenueId: number;
  orgUid: string;
  showingId: number;
  basketTickets: TicketItem[];
  analyticsConfigs?: Analytics[];
}

const EventGASelection = ({
  prices,
  eventId,
  eventName,
  eventVenueId,
  orgUid,
  showingId,
  basketTickets,
  analyticsConfigs,
}: Props) => {
  const [hoveredBadge, setHoveredBadge] = useState<string | null>(null);
  const [dynamicPrice, setDynamicPrice] = useState(0);
  const { setFailure, setSuccess } = useNotificationStore((state) => state);

  const selected_quantities = prices.map((p) => ({
    id: p.id,
    qty: p.bookedTickets || 0,
    qty_from_combo: p.bookedFromCombo,
  }));

  async function deleteTicket(ticketsToDelete: any) {
    const basketKey = getCookieValue("ASP.NET_SessionId");
    try {
      const deletedTicket = await axios.delete(
        `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}/ticket/${ticketsToDelete.UniqueId}`,
        {
          headers: {
            "itk-website-uid": process.env.NEXT_PUBLIC_ITK_WEBSITE_UID,
          },
        }
      );

      return deletedTicket.status;
    } catch (error) {
      return { error };
    }
  }

  const putQtyInCart = async (
    id: string,
    type: string,
    priceAgeId: number | null,
    priceSectionId: number | null,
    priceId: number | null,
    gaAreaId: number | null,
    qty: string,
    isCombo: boolean,
    price: number,
    comboId: number | null,
    isDynamic?: boolean
  ) => {
    const bookedTickets = selected_quantities.find((sq) => sq.id === id)?.qty;
    let toBookTickets = parseInt(qty) * 1;
    if (!isDynamic) {
      toBookTickets = toBookTickets - (bookedTickets || 0);
    }

    const basketKey = getCookieValue("ASP.NET_SessionId");

    if (toBookTickets < 0 && isCombo) {
      try {
        let deletedTickets: any[] = [];
        for (let i = 0; i < Math.abs(toBookTickets); ++i) {
          const freshBasketTickets =
            i > 0
              ? await axios
                  .get(
                    `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}`,
                    {
                      headers: {
                        "itk-website-uid":
                          process.env.NEXT_PUBLIC_ITK_WEBSITE_UID,
                      },
                    }
                  )
                  .then((res) => res.data)
                  .then(
                    (data) =>
                      data.Events.find(
                        (event: any) => event.Id === eventId
                      )?.Showings.find(
                        (showing: any) => showing.Id === showingId
                      )?.Items
                  )
              : basketTickets;

          if (!freshBasketTickets) return "failure";

          const ticketToDelete = freshBasketTickets.find(
            (ticket: any) =>
              ticket.ComboId === comboId &&
              !deletedTickets.includes(ticket.UniqueId)
          );
          await axios.delete(
            `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}/ticket/${ticketToDelete.UniqueId}`,
            {
              headers: {
                "itk-website-uid": process.env.NEXT_PUBLIC_ITK_WEBSITE_UID,
              },
            }
          );
          deletedTickets.push(ticketToDelete.UniqueId);
        }
        await mutate(
          (key) =>
            Array.isArray(key) &&
            key[0].startsWith(
              `events/${eventId}/${eventVenueId}/showings/${showingId}/tickets/ga`
            )
        );
        await mutate(`events/${eventId}/${eventVenueId}/showings`);
        await mutate(`basket/${basketKey}/summary`);
        await mutate(`basket/${basketKey}`);
        await mutate(`basket/${basketKey}/form`);
        setSuccess(`Successfully removed your combo.`);
        return "success";
      } catch (err) {
        setFailure("Something went wrong trying to remove your combo.", true);
        return "failure";
      }
    }

    if (toBookTickets < 0) {
      let ticket_promises: Promise<any>[] = [];

      for (let i = 0; ticket_promises.length < Math.abs(toBookTickets); ++i) {
        if (
          basketTickets[i].PriceId === priceId &&
          basketTickets[i].Combo === null
        ) {
          ticket_promises.push(deleteTicket(basketTickets[i]));
        }
      }

      const deletedTickets = await Promise.all(ticket_promises);

      const allOk = deletedTickets.every((ticket) => ticket === 200);

      const itemPrice = isDynamic && dynamicPrice !== 0 ? dynamicPrice : price;

      if (deletedTickets.length > 0 && allOk) {
        analytics.track("Product Removed", {
          product_id: eventId,
          sku: `${eventId}-${showingId}-${priceAgeId}`,
          name: eventName,
          variant: type,
          price: itemPrice,
          quantity: deletedTickets.length,
          currency: currencyLookup[COUNTRY_CODE ?? "nz"].currency,
          value: itemPrice * deletedTickets.length,
          cart_id: basketKey,
          organisationUid: orgUid,
        });

        const trackingData = {
          event: "remove_from_cart",
          currency: currencyLookup[COUNTRY_CODE ?? "nz"].currency,
          value: itemPrice * deletedTickets.length,
          items: [
            {
              item_id: eventId,
              sku: `${eventId}-${showingId}-${priceAgeId}`,
              item_name: eventName,
              item_variant: type,
              price: itemPrice,
              quantity: deletedTickets.length,
            },
          ],
        };

        sendIticketGTMTrackingEvent(trackingData);

        if (analyticsConfigs) {
          sendGTMTrackingEvent(trackingData, analyticsConfigs);
        }

        await mutate(
          (key: any) =>
            Array.isArray(key) &&
            key[0].startsWith(
              `events/${eventId}/${eventVenueId}/showings/${showingId}/tickets/ga`
            )
        );
        await mutate(`events/${eventId}/${eventVenueId}/showings`);
        await mutate(`basket/${basketKey}/summary`);
        await mutate(`basket/${basketKey}`);
        await mutate(`basket/${basketKey}/form`);
        setSuccess(`Successfully removed your tickets.`);
        return "success";
      } else {
        setFailure(
          "Something went wrong when trying to remove your tickets.",
          true
        );
        return "failure";
      }
    }

    try {
      const response = isCombo
        ? await axios.post(
            `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}/tickets/ga/combo`,
            {
              combos: [
                {
                  qty: toBookTickets,
                  eventId: eventId,
                  eventVenueId: eventVenueId,
                  comboId: comboId,
                  showingId: showingId,
                  password: "",
                },
              ],
            },
            {
              headers: {
                "itk-website-uid": process.env.NEXT_PUBLIC_ITK_WEBSITE_UID,
              },
            }
          )
        : await axios.post(
            `${BASE_URL}/legacy/${COUNTRY_CODE}/shop/basket/${basketKey}/tickets/ga`,
            {
              tickets: [
                {
                  qty: toBookTickets,
                  eventId: eventId,
                  eventVenueId: eventVenueId,
                  priceSectionId: priceSectionId,
                  priceAgeId: priceAgeId,
                  showingId: showingId,
                  gaAreaId: gaAreaId,
                  password: "",
                  ...(isDynamic && {
                    price: dynamicPrice !== 0 ? dynamicPrice : price,
                  }),
                },
              ],
            },
            {
              headers: {
                "itk-website-uid": process.env.NEXT_PUBLIC_ITK_WEBSITE_UID,
              },
            }
          );

      if (!response.data.successfull) {
        throw new Error(response.data.errors[0]);
      }

      const itemPrice = isDynamic && dynamicPrice !== 0 ? dynamicPrice : price;

      if (response.status >= 200 && response.status < 300) {
        analytics.track("Product Added", {
          product_id: eventId,
          sku: `${eventId}-${showingId}-${priceAgeId}`,
          name: eventName,
          variant: type,
          price: itemPrice,
          quantity: toBookTickets,
          currency: currencyLookup[COUNTRY_CODE ?? "nz"].currency,
          value: itemPrice * toBookTickets,
          cart_id: basketKey,
          organisationUid: orgUid,
        });

        const trackingData = {
          event: "add_to_cart",
          currency: currencyLookup[COUNTRY_CODE ?? "nz"].currency,
          value: itemPrice * toBookTickets,
          items: [
            {
              item_id: eventId,
              sku: `${eventId}-${showingId}-${priceAgeId}`,
              item_name: eventName,
              item_variant: type,
              price: itemPrice,
              quantity: toBookTickets,
            },
          ],
        };

        sendIticketGTMTrackingEvent(trackingData);

        if (analyticsConfigs) {
          sendGTMTrackingEvent(trackingData, analyticsConfigs);
        }

        await mutate(
          (key: any) =>
            Array.isArray(key) &&
            key[0].startsWith(
              `events/${eventId}/${eventVenueId}/showings/${showingId}/tickets/ga`
            )
        );
        await mutate(`events/${eventId}/${eventVenueId}/showings`);
        await mutate(`basket/${basketKey}/summary`);
        await mutate(`basket/${basketKey}`);
        await mutate(`basket/${basketKey}/form`);
        setSuccess(`Successfully added ticket to cart`);
        return "success";
      }
    } catch (error: any) {
      if (
        error?.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        setFailure(error.response.data.message);
      } else if (error.message) {
        setFailure(error.message);
      } else {
        setFailure("Something went wrong.", true);
      }
      return "failure";
    }
    return "success";
  };

  return (
    <div
      data-testid="eventgaselector"
      className="sm:border bg-white sm:rounded-lg relative"
    >
      <div className="hidden sm:flex w-full p-4 border-b">
        <div className="w-36 pr-4 text-sm font-semibold text-black">Type</div>
        <div className="block flex-1 text-sm font-semibold text-black">
          Info
        </div>
        <div className="text-sm w-32 pr-4 text-right font-semibold text-black">
          Price
        </div>
        <div className="w-0 sm:w-16"></div>
      </div>
      <div className="hidden sm:block divide-y">
        {prices?.map((price) => (
          <div
            key={price.id}
            className={joinClassNames(
              "flex w-full items-center justify-between p-4",
              (
                price.isCombo
                  ? price.max === 0 || price.max === price.bookedTickets
                  : (price.status !== PriceStatus.AVAILABLE &&
                      selected_quantities.find((sq) => sq.id === price.id)
                        ?.qty === 0) ||
                    (price.bookingLimit ?? price.max) < price.min
              )
                ? "bg-gray-100"
                : ""
            )}
          >
            <div className="w-36 pr-4 text-left text-sm font-semibold">
              <span className="flex flex-col gap-0.5 w-fit">
                {price.type}
                {price.isCombo ? (
                  price.max === 0 ? (
                    <span className="-ml-1 px-2 py-1 rounded-lg w-fit text-sm bg-red-100 text-red-800 font-semibold">
                      Sold Out
                    </span>
                  ) : price.max === price.bookedTickets ? (
                    <span className="-ml-1 px-2 py-1 rounded-lg w-fit text-sm bg-red-100 text-red-800 font-semibold">
                      Max Bought
                    </span>
                  ) : null
                ) : (
                  ((price.status !== PriceStatus.AVAILABLE &&
                    selected_quantities.find((sq) => sq.id === price.id)
                      ?.qty === 0) ||
                    (price.bookingLimit ?? price.max) < price.min) && (
                    <span className="-ml-1 px-2 py-1 rounded-lg w-fit text-sm bg-red-100 text-red-800 font-semibold">
                      {price.status === PriceStatus.NOTAVAILABLE
                        ? "Not Available"
                        : price.status === PriceStatus.SOLDOUT
                        ? "Sold Out"
                        : price.status === PriceStatus.BOOKEDOUT
                        ? "Booked Out"
                        : "Limit Reached"}
                    </span>
                  )
                )}
              </span>
            </div>
            <div className="flex flex-col gap-2 line-clamp-2 flex-1 text-left pr-4 text-sm text-gray-500">
              {price.info ? <span>{price.info}</span> : <></>}
              <div className="flex gap-2">
                {price.isPromo ? (
                  <motion.span
                    onHoverStart={() => setHoveredBadge(`${price.id}-promo`)}
                    onHoverEnd={() => setHoveredBadge(null)}
                    animate={
                      hoveredBadge === `${price.id}-promo`
                        ? {
                            flexShrink: 0,
                          }
                        : {
                            flexShrink: 1,
                          }
                    }
                    className={joinClassNames(
                      "flex gap-1 items-center px-2 py-1 rounded-lg truncate text-sm bg-yellow-200 text-yellow-800 font-semibold w-fit transition-opacity ease-in-out duration-200 cursor-default",
                      hoveredBadge === `${price.id}-promo` ||
                        hoveredBadge === null ||
                        !hoveredBadge.includes(price.id)
                        ? "opacity-100"
                        : "opacity-70"
                    )}
                  >
                    <SparklesIcon className="h-4 w-4" />
                    PROMO
                    <SparklesIcon className="h-4 w-4" />
                  </motion.span>
                ) : (
                  <></>
                )}
                {price.isCombo ? (
                  <motion.span
                    onHoverStart={() => setHoveredBadge(`${price.id}-combo`)}
                    onHoverEnd={() => setHoveredBadge(null)}
                    animate={
                      hoveredBadge === `${price.id}-combo`
                        ? {
                            flexShrink: 0,
                          }
                        : {
                            flexShrink: 1,
                          }
                    }
                    className={joinClassNames(
                      "px-2 py-1 rounded-lg truncate text-sm bg-green-200 text-green-800 font-semibold w-fit transition-opacity ease-in-out duration-200 cursor-default",
                      hoveredBadge === `${price.id}-combo` ||
                        hoveredBadge === null ||
                        !hoveredBadge.includes(price.id)
                        ? "opacity-100"
                        : "opacity-70"
                    )}
                  >
                    COMBO
                  </motion.span>
                ) : (
                  <></>
                )}
                {price.areaName ? (
                  <motion.span
                    onHoverStart={() => setHoveredBadge(`${price.id}-area`)}
                    onHoverEnd={() => setHoveredBadge(null)}
                    animate={
                      hoveredBadge === `${price.id}-area`
                        ? {
                            flexShrink: 0,
                          }
                        : {
                            flexShrink: 1,
                          }
                    }
                    className={joinClassNames(
                      "px-2 py-1 rounded-lg truncate text-sm bg-sky-100 text-sky-800 font-semibold w-fit transition-opacity ease-in-out duration-200 cursor-default",
                      hoveredBadge === `${price.id}-area` ||
                        hoveredBadge === null ||
                        !hoveredBadge.includes(price.id)
                        ? "opacity-100"
                        : "opacity-70"
                    )}
                  >
                    {price.areaName}
                  </motion.span>
                ) : (
                  <></>
                )}
                {price.priceSchemeName ? (
                  <motion.span
                    onHoverStart={() =>
                      setHoveredBadge(`${price.id}-price_scheme`)
                    }
                    onHoverEnd={() => setHoveredBadge(null)}
                    animate={
                      hoveredBadge === `${price.id}-price_scheme`
                        ? {
                            flexShrink: 0,
                          }
                        : {
                            flexShrink: 1,
                          }
                    }
                    className={joinClassNames(
                      "px-2 py-1 rounded-lg truncate text-sm bg-cyan-100 text-cyan-800 font-semibold w-fit transition-opacity ease-in-out duration-200 cursor-default",
                      hoveredBadge === `${price.id}-price_scheme` ||
                        hoveredBadge === null ||
                        !hoveredBadge.includes(price.id)
                        ? "opacity-100"
                        : "opacity-70"
                    )}
                  >
                    {price.priceSchemeName}
                  </motion.span>
                ) : (
                  <></>
                )}
                {price.priceSectionName ? (
                  <motion.span
                    onHoverStart={() =>
                      setHoveredBadge(`${price.id}-price_section`)
                    }
                    onHoverEnd={() => setHoveredBadge(null)}
                    animate={
                      hoveredBadge === `${price.id}-price_section`
                        ? {
                            flexShrink: 0,
                          }
                        : {
                            flexShrink: 1,
                          }
                    }
                    className={joinClassNames(
                      "px-2 py-1 rounded-lg truncate text-sm bg-teal-100 text-teal-800 font-semibold w-fit transition-opacity ease-in-out duration-200 cursor-default",
                      hoveredBadge === `${price.id}-price_section` ||
                        hoveredBadge === null ||
                        !hoveredBadge.includes(price.id)
                        ? "opacity-100"
                        : "opacity-70"
                    )}
                  >
                    {price.priceSectionName}
                  </motion.span>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {price.priceMaximum !== null &&
            price.priceMaximum > 0 &&
            price.priceMinimum !== null &&
            price.priceMinimum > 0 ? (
              <span className="relative w-32 text-right text-sm tabular-nums">
                <FlexiPricePopover
                  price={price}
                  selectedQuantities={selected_quantities}
                  dynamicPrice={dynamicPrice}
                  setDynamicPrice={setDynamicPrice}
                  putQtyInCart={putQtyInCart}
                />
              </span>
            ) : (
              <>
                <span className="relative w-32 pr-4 text-right text-sm tabular-nums">
                  <span
                    className={joinClassNames(
                      price.isCombo ? "relative text-black" : ""
                    )}
                  >
                    {price.isCombo ? (
                      <>
                        {price.price > price.discountPrice ? (
                          <span className="absolute -bottom-4 rounded-lg w-fit text-xs text-gray-400 font-semibold line-through">
                            {getCurrencyFormat(price.price)}
                          </span>
                        ) : (
                          <></>
                        )}
                        {getCurrencyFormat(price.discountPrice)}
                      </>
                    ) : (
                      <> {getCurrencyFormat(price.price)}</>
                    )}
                  </span>
                </span>
                <AddTicketDropdown
                  price={price}
                  putQtyInCart={putQtyInCart}
                  selectedQuantities={selected_quantities}
                />
              </>
            )}
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-4 sm:hidden">
        {prices.map((price) => (
          <MobileTicketDisplay
            key={price.id}
            price={price}
            putQtyInCart={putQtyInCart}
            selectedQuantities={selected_quantities}
          />
        ))}
      </div>
    </div>
  );
};

export default EventGASelection;
