"use client";

import {
  BuildingOfficeIcon,
  CalendarDaysIcon,
  ClockIcon,
  CurrencyDollarIcon,
  MapPinIcon,
  TicketIcon,
} from "@heroicons/react/20/solid";
import {
  TicketIcon as TicketIconOutline,
  CurrencyDollarIcon as CurrencyDollarIconOutline,
} from "@heroicons/react/24/outline";
import { EventData } from "@types";
import { format, parseISO } from "date-fns";
import Image from "next/image";
import EventToolTip from "./components/eventToolTip";
import { useSearchParams } from "next/navigation";
import { useWindowSize } from "@utils/hooks";
import { MegaphoneIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import Outlet from "./components/outlet";

interface Props {
  event: EventData;
}

export default function EventInfo({ event }: Props) {
  const portraitImage = event.images
    .filter((i) => i.format.name.includes("Portrait"))
    .sort((a, b) => {
      if (a.format.width > b.format.width) {
        return -1;
      }
      if (a.format.width < b.format.width) {
        return 1;
      }
      return 0;
    })[0];
  const windowSize = useWindowSize();
  const searchParams = useSearchParams();
  const viewMode = searchParams.get("view");

  return windowSize.width &&
    windowSize.width < 1024 &&
    !viewMode?.includes("info") &&
    event.status !== "past" ? (
    <></>
  ) : (
    <>
      {portraitImage ? (
        <Image
          className="rounded-xl hidden lg:block"
          src={`${portraitImage.path.processed.trim()}?v=${
            portraitImage.version
          }`}
          width={264}
          height={308}
          alt="Event Image"
          priority
          quality={100}
        />
      ) : (
        <div className="hidden lg:flex items-center justify-center rounded-xl aspect-[6/7] max-w-[264px] bg-indigo-100">
          <MegaphoneIcon className="text-indigo-800 w-1/2 h-1/2" />
        </div>
      )}
      <div className="flex flex-col gap-4 mt-5">
        <div className="flex flex-col gap-1 bg-gray-50 rounded-2xl px-4 py-2">
          <div className="flex flex-row lg:flex-col xl:flex-row items-center lg:items-start lg:justify-center xl:items-center xl:justify-between gap-2">
            <div className="flex gap-1 items-center">
              <MapPinIcon className="h-5 w-5 text-black" aria-hidden="true" />
              <h2 className="text-base font-semibold text-black">Where</h2>
            </div>
            {event.venue.slug ? (
              <Link
                href={event.venue.slug}
                className="flex gap-1 items-center px-2 py-1 w-fit rounded-lg bg-indigo-100 text-indigo-800 hover:bg-indigo-500 hover:text-white transition-colors ease-in-out duration-200"
              >
                <BuildingOfficeIcon className="h-5 w-5" />
                <span className="font-medium">View venue</span>
              </Link>
            ) : (
              <></>
            )}
          </div>
          <div>
            <p className="text-gray-500">{event.venue.name}</p>
            <p className="text-gray-500">{event.venue.location}</p>
            <p className="text-gray-500">
              {event.venue.region ? `${event.venue.region}, ` : ""}
              {event.venue.country}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-1 bg-gray-50 rounded-2xl px-4 py-2">
          <div className="flex gap-1 items-center">
            <CalendarDaysIcon
              className="h-5 w-5 text-black"
              aria-hidden="true"
            />
            <h2 className="text-base font-semibold text-black">When</h2>
          </div>
          <p className="text-gray-500">
            {event.info.daterange
              ? event.info.daterange
              : `${format(
                  parseISO(event.showings[0].date.local.start),
                  `EEE d MMM yyyy`
                )}${
                  event.showings.length > 1 &&
                  format(
                    parseISO(event.showings[0].date.local.start),
                    `EEE d MMM yyyy`
                  ) !==
                    format(
                      parseISO(
                        event.showings[event.showings.length - 1].date.local
                          .start
                      ),
                      `EEE d MMM yyyy`
                    )
                    ? ` - ${format(
                        parseISO(
                          event.showings[event.showings.length - 1].date.local
                            .start
                        ),
                        `EEE d MMM yyyy`
                      )}`
                    : ""
                }`}
          </p>
        </div>
        {event.info.runningtime !== "" ? (
          <div className="flex flex-col gap-1 bg-gray-50 rounded-2xl px-4 py-2">
            <div className="flex gap-1 items-center">
              <ClockIcon className="h-5 w-5 text-black" aria-hidden="true" />
              <h2 className="text-base font-semibold text-black">
                Running time
              </h2>
            </div>
            <p className="text-gray-500">{event.info.runningtime}</p>
          </div>
        ) : (
          <></>
        )}
        {event.info.intervaltime !== "" ? (
          <div className="flex flex-col gap-1 bg-gray-50 rounded-2xl px-4 py-2">
            <h2 className="text-base font-semibold text-black">Interval</h2>
            <p className="text-gray-500">{event.info.intervaltime}</p>
          </div>
        ) : (
          <></>
        )}
        {event.delivery.filter(
          (delivery) =>
            delivery.type.public &&
            !delivery.type.free &&
            !delivery.name.toLowerCase().includes("real time")
        ).length > 0 && (
          <div className="flex flex-col gap-1 bg-gray-50 rounded-2xl px-4 py-2">
            <div className="flex gap-1 items-center">
              <TicketIcon className="h-5 w-5 text-black" aria-hidden="true" />
              <h2 className="text-base font-semibold text-black">
                Delivery options
              </h2>
              {event.delivery.filter(
                (delivery) =>
                  delivery.type.public &&
                  !delivery.type.free &&
                  !delivery.name.toLowerCase().includes("real time")
              ).length > 1 ? (
                <EventToolTip
                  title="Delivery"
                  description="Depending on tickets selected in your cart, not all delivery options may be
                available at time of purchase."
                  icon={
                    <TicketIconOutline className="absolute top-0 left-4 w-24 h-24 text-white/10 -rotate-12" />
                  }
                />
              ) : null}
            </div>
            <div>
              {event.delivery
                .filter(
                  (delivery) =>
                    delivery.type.public &&
                    !delivery.type.free &&
                    !delivery.name.toLowerCase().includes("real time")
                )
                .map((delivery) => (
                  <p key={delivery.id} className="text-gray-500">
                    {delivery.name}
                  </p>
                ))}
            </div>
          </div>
        )}
        <div className="flex flex-col gap-1 bg-gray-50 rounded-2xl px-4 py-2">
          <div className="flex gap-1 items-center">
            <CurrencyDollarIcon
              className="h-5 w-5 text-black"
              aria-hidden="true"
            />
            <h2 className="text-base font-semibold text-black">
              Payment options
            </h2>
            {event.payment.filter(
              (payment) => payment.type.public && payment.type.website
            ).length > 1 ? (
              <EventToolTip
                title="Payment"
                description="Depending on tickets selected in your cart, not all payment options may be
              available at time of purchase."
                icon={
                  <CurrencyDollarIconOutline className="absolute top-0 left-4 w-24 h-24 text-white/10 -rotate-12" />
                }
              />
            ) : null}
          </div>
          <div>
            {event.payment
              .filter((payment) => payment.type.public && payment.type.website)
              .map((payment) => (
                <div
                  key={payment.id}
                  className="flex justify-between items-center"
                >
                  <div className="text-gray-500 ">{payment.name}</div>
                </div>
              ))}
          </div>
        </div>

        {event.outlets.length > 0 ? <Outlet outlets={event.outlets} /> : <></>}
      </div>
    </>
  );
}
