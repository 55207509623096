import { TrashIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { EventShowing } from "@types";
import { joinClassNames } from "@utils/helpers";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

interface Props {
  showingcomboconfig: {
    connectedShowings: number[];
    maxShowings: number;
    minShowings: number;
  };
  activeShowings: EventShowing[];
  children: (selectedConnectedShowings: number[]) => JSX.Element;
}

const selected = {
  hide: {
    height: 0,
    marginBottom: 0,
    transition: {
      delay: 0.2,
    },
  },
  show: {
    height: "auto",
    marginBottom: "1.25rem",
  },
};

const container = {
  hide: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.05,
    },
  },
};

const item = {
  hide: {
    opacity: 0,
    x: 5,
  },
  show: {
    opacity: 1,
    x: 0,
  },
};

export default function ConnectedShowings({
  showingcomboconfig,
  activeShowings,
  children,
}: Props) {
  const [selectedConnectedShowings, setSelectedConnectedShowings] = useState<
    number[]
  >([]);
  const minSelectsRemaining =
    showingcomboconfig.minShowings - selectedConnectedShowings.length;
  const maxSelectsRemaining =
    showingcomboconfig.maxShowings - selectedConnectedShowings.length;

  return (
    <div className="flex flex-col">
      <motion.div
        variants={selected}
        initial="hide"
        animate={selectedConnectedShowings.length > 0 ? "show" : "hide"}
        className="flex flex-col gap-2"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={
            selectedConnectedShowings.length > 0
              ? { opacity: 1, transition: { delay: 0.2 } }
              : { opacity: 0 }
          }
          className="flex items-center justify-between"
        >
          <h2 className="text-lg font-semibold text-black truncate">
            Selected showings
          </h2>
          <AnimatePresence>
            <button
              onClick={() => setSelectedConnectedShowings([])}
              className="flex gap-1 items-center px-2 py-1 w-fit rounded-lg bg-red-100 text-red-800 hover:bg-red-500 hover:text-white transition-colors ease-in-out duration-200"
            >
              <TrashIcon className="h-5 w-5" />
              <span className="font-semibold text-base">Clear all</span>
            </button>
          </AnimatePresence>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={
            selectedConnectedShowings.length > 0
              ? { opacity: 1, transition: { delay: 0.2 } }
              : { opacity: 0 }
          }
          className="flex items-center gap-1 flex-wrap"
        >
          {showingcomboconfig.connectedShowings.map((showing_id) => {
            const connectedShowing = activeShowings.find(
              (showing) => showing.id === showing_id
            );
            if (!connectedShowing) {
              return <></>;
            }
            return (
              <AnimatePresence key={showing_id}>
                {selectedConnectedShowings.includes(showing_id) && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex items-center gap-0.5 px-2 py-1 rounded-lg bg-indigo-100 text-indigo-800"
                  >
                    <span className="text-sm font-bold">
                      {connectedShowing.name}
                    </span>
                    <button
                      onClick={() => {
                        setSelectedConnectedShowings((prev) =>
                          prev.filter((id) => id !== showing_id)
                        );
                      }}
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </motion.div>
                )}
              </AnimatePresence>
            );
          })}
        </motion.div>
      </motion.div>
      <motion.div layout="position">
        <motion.h2
          initial={{ opacity: 0, height: 0, marginBottom: 0 }}
          animate={
            maxSelectsRemaining > 0
              ? {
                  opacity: 1,
                  height: "auto",
                  marginBottom: "1.25rem",
                  transition: { opacity: { delay: 0.2 } },
                }
              : {
                  opacity: 0,
                  height: 0,
                  marginBottom: 0,
                  transition: {
                    height: { delay: 0.2 },
                    marginBottom: { delay: 0.2 },
                  },
                }
          }
          transition={{ delay: 0.2 }}
          className="text-lg font-semibold text-black truncate"
        >
          {`Choose ${Math.max(maxSelectsRemaining, 1)} ${
            selectedConnectedShowings.length > 0 ? "more" : ""
          } showing${maxSelectsRemaining > 1 ? "s" : ""}`}
        </motion.h2>
        <AnimatePresence>
          {maxSelectsRemaining > 0 && (
            <motion.div
              initial={{ opacity: 1, height: "auto" }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
            >
              <motion.div
                variants={container}
                initial="hide"
                animate="show"
                className="isolate grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6"
              >
                {showingcomboconfig.connectedShowings.map((showingId) => {
                  const connectedShowing = activeShowings.find(
                    (showing) => showing.id === showingId
                  );
                  if (!connectedShowing) {
                    return <></>;
                  }
                  return (
                    <motion.button
                      key={showingId}
                      variants={item}
                      onClick={() =>
                        setSelectedConnectedShowings((prev) =>
                          !prev.includes(showingId) && minSelectsRemaining > 0
                            ? [...prev, showingId]
                            : prev.includes(showingId)
                            ? prev.filter((id) => id !== showingId)
                            : prev
                        )
                      }
                      className={joinClassNames(
                        "relative rounded-md border shadow border-gray-100 transition-colors ease-in-out duration-200",
                        maxSelectsRemaining === 0 ||
                          selectedConnectedShowings.includes(showingId)
                          ? "hidden"
                          : "block"
                      )}
                    >
                      <div className="flex items-center justify-between gap-1 px-4 py-2">
                        <div className="flex flex-col overflow-hidden justify-center text-base font-medium text-black w-full">
                          <p className="text-left truncate">
                            {connectedShowing?.name}
                          </p>
                          <span className="text-left text-sm text-indigo-500 w-fit">
                            {connectedShowing?.date.display
                              ? connectedShowing.date.display
                              : connectedShowing?.date.local.formatted}
                          </span>
                        </div>
                      </div>
                    </motion.button>
                  );
                })}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {minSelectsRemaining === 0 && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
            >
              {children(selectedConnectedShowings)}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
}
