"use client";

import { Analytics, GTMData } from "@types";
import { sendGTMTrackingEvent } from "@utils/analyticsUtils";
import { useEffect, useRef } from "react";

export default function TrackGTMPageView({
  pageTitle,
  config,
}: {
  pageTitle: string;
  config: Analytics[];
}) {
  const pageviewTriggered = useRef(false);

  useEffect(() => {
    if (typeof window !== "undefined" && !pageviewTriggered.current) {
      const data: GTMData = {
        event: "page_view",
        page_title: pageTitle,
      };

      sendGTMTrackingEvent(data, config);
      pageviewTriggered.current = true;
    }
  }, [pageTitle, config]);

  return null;
}
