import { Pricesection } from "@types";
import React from "react";
import { ArrowTrendingUpIcon } from "@heroicons/react/24/outline";
import { getCurrencyFormat } from "@utils/helpers";

interface Props {
  pricesection: Pricesection;
  priceschemeName: string;
}

interface CombinedArray {
  id: number;
  name: string;
  description: string;
  price: number;
  isDynamic?: boolean;
}

const PriceSection = ({ pricesection, priceschemeName }: Props) => {
  const priceages = pricesection.priceages
    ?.filter(
      (pa) =>
        (pa.type === "standard" || pa.type === "extra") &&
        pa.active &&
        pa.display
    )
    .map((priceage) => ({
      id: priceage.id,
      name: priceage.name,
      description: priceage.description,
      display: priceage.display,
      price: priceage.price.price,
      isDynamic: priceage.price.type.id === 2,
      order: priceage.order,
    }))
    .sort((a, b) => a.order - b.order);

  const manualprices = pricesection.manualprices
    ?.filter((manualprice) => manualprice.active)
    .map((manualprice) => ({
      id: manualprice.id,
      name: manualprice.name,
      description: manualprice.description,
      price: manualprice.price,
      order: manualprice.order,
    }))
    .sort((a, b) => a.order - b.order);

  const combinedArrays: CombinedArray[] = [
    ...(priceages ?? []),
    ...(manualprices ?? []),
  ];

  if (!pricesection.priceages && !pricesection.manualprices) return null;
  if (combinedArrays.length <= 0) return null;
  return (
    <div
      key={pricesection.id}
      className="flex gap-y-2 flex-col bg-indigo-100 rounded-2xl p-4"
    >
      <div className="shrink-0 flex-1 flex flex-col">
        <span className="text-xl text-indigo-600 font-extrabold">
          {priceschemeName.trim()}
          {priceschemeName && pricesection.name.trim() ? " - " : ""}
          {pricesection.name.trim()}
        </span>
        {pricesection.description && (
          <span className="text-gray-500">{pricesection.description}</span>
        )}
      </div>
      {combinedArrays?.map((price) => (
        <div
          key={price.id}
          className="flex items-center justify-between gap-4 sm:gap-6"
        >
          <div className="min-w-0 flex-auto">
            <div className="flex justify-between items-center gap-2">
              <p className="text-indigo-600 font-medium text-sm">
                {price.name}
              </p>
              <span className="text-indigo-600">
                {getCurrencyFormat(price.price)}
              </span>
            </div>
            {price.isDynamic ? (
              <span className="text-sm bg-iticket text-white px-2 py-0.5 font-semibold inline-flex items-center rounded-md gap-1 mt-1">
                <ArrowTrendingUpIcon className="h-4 w-4" />
                FLEXi
              </span>
            ) : (
              <></>
            )}
            {price.description && (
              <p className="text-sm leading-5 text-gray-500 mt-1">
                {price.description}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PriceSection;
