import { Skeleton } from "@components/wrapper";

const SkeletonLoading = () => {
  return (
    <div className="overflow-hidden mt-10 max-sm:-mx-4 max-sm:-mb-2 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
      {Array.from({ length: 5 }).map((_, index) => (
        <div key={index} className="border-b">
          <div className="flex">
            <div className="flex w-full items-center justify-between gap-x-6 px-4 py-5 sm:px-6 ">
              <div className="relative flex gap-x-4">
                <Skeleton className="h-6 w-6 flex-none rounded bg-gray-200" />
                <div className="min-w-0 flex-auto">
                  <Skeleton className="h-6 w-40 flex-none rounded bg-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonLoading;
