import { useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../popovers/dialog";
import { AnimatePresence, motion } from "framer-motion";
import {
  CheckCircleIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { joinClassNames } from "@utils/helpers";

interface Props {
  description?: string;
  /**
   * The rendered element that will act as a trigger for the dropdown
   * @param selected
   * @returns
   */
  children: (selected: string) => React.ReactNode;
  defaultValue?: string;
  /**
   * Handle the selection of options
   * @param option
   * @returns
   */
  onOptionSelect: (option: string) => void;
  /**
   * Options for the dropdown. It can either be an array of strings or numbers
   */
  options: string[] | number[];
  onOpenChange?: (open: boolean) => void;
}

/**
 * This component should only be called only on mobile view, replacing dropdowns to better suit for mobile viewing
 *
 */
export default function MobileSelect({
  description = "",
  children,
  defaultValue = "",
  onOptionSelect,
  options,
  onOpenChange,
}: Props) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultValue);

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (onOpenChange) {
          onOpenChange(open);
        }
      }}
    >
      <DialogTrigger asChild>{children(selected)}</DialogTrigger>
      <AnimatePresence>
        {open && (
          <DialogContent
            asChild
            forceMount
            handleClose={() => {
              setOpen(false);
              if (onOpenChange) {
                onOpenChange(false);
              }
            }}
            className="bottom-4 !m-2 !p-0 !rounded-3xl !bg-white !max-w-[90dvw] max-h-[65dvh] !text-indigo-800 !border-none"
          >
            <motion.div
              initial="hidden"
              animate="show"
              exit="hidden"
              className="flex flex-col w-full"
              transition={{ duration: 0.2 }}
              variants={{
                hidden: {
                  y: 200,
                  opacity: 0,
                },
                show: {
                  y: 0,
                  opacity: 1,
                },
              }}
            >
              <div className="relative px-6 pt-6 pb-4 flex flex-col gap-3 justify-between flex-1 bg-indigo-500 rounded-t-3xl">
                <div className="relative self-end w-8 h-8 rounded-full bg-white/10">
                  <DialogClose className="absolute top-2 left-2 w-4 h-4 text-white !font-bold">
                    <XMarkIcon />
                  </DialogClose>
                </div>
                <DialogHeader className="!text-left">
                  <DialogTitle className="!text-white text-xl !font-bold">
                    Select an option
                  </DialogTitle>
                  {description ? (
                    <DialogDescription className="!text-white/75">
                      {description}
                    </DialogDescription>
                  ) : (
                    <></>
                  )}
                  <CheckCircleIcon className="absolute top-0 left-4 w-24 h-24 text-white/10 -rotate-12" />
                </DialogHeader>
              </div>
              <div className="max-h-[calc(65dvh-116px)] overflow-y-auto">
                {options.map((option) => (
                  <button
                    onClick={() => {
                      setSelected(option.toString());
                      onOptionSelect(option.toString());
                      setOpen(false);
                    }}
                    key={option}
                    className="flex flex-col items-center w-full "
                  >
                    <div className="relative flex items-center">
                      {option.toString() === selected ? (
                        <CheckIcon className="absolute right-[calc(100%-1rem)] top-1.5 stroke-[3] text-indigo-500 w-7 h-7" />
                      ) : (
                        <></>
                      )}
                      <div
                        className={joinClassNames(
                          "py-2 px-6 w-fit text-2xl font-black",
                          option.toString() === selected
                            ? "text-indigo-500"
                            : "text-black"
                        )}
                      >
                        {option}
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </motion.div>
          </DialogContent>
        )}
      </AnimatePresence>
    </Dialog>
  );
}
