import React, { useState } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import {
  ClockIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useWindowSize } from "@utils/hooks";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/popovers/dialog";
import { AnimatePresence, motion } from "framer-motion";
import { ShowingStatus, AccessType } from "@utils/enums";
import { joinClassNames } from "@utils/helpers";

interface Props {
  title: string;
  description: string;
  statusId: number;
  accessType: string;
}

const ShowingStatusToolTip = ({
  title,
  description,
  statusId,
  accessType,
}: Props) => {
  const windowSize = useWindowSize();
  const [open, setOpen] = useState(false);

  const handleColor = () => {
    if (
      statusId === ShowingStatus.SOLDOUT ||
      statusId === ShowingStatus.ADMINSOLDOUT ||
      statusId === ShowingStatus.CANCELLED
    ) {
      return {
        bgColor: "bg-red-100",
        dialogBgColor: "bg-red-500",
        dialogCloseHover: "hover:bg-red-400",
        dialogDisabled: "disabled:bg-red-200",
        iconColor: "text-red-600",
        toolTipContent: "text-red-800",
        toolTipDescription: "text-red-700",
        toolTipArrow: "fill-red-100",
      };
    }

    if (
      statusId === ShowingStatus.POSTPONED ||
      statusId === ShowingStatus.NOTYETONSALE
    ) {
      return {
        bgColor: "bg-orange-100",
        dialogBgColor: "bg-orange-500",
        dialogCloseHover: "hover:bg-orange-400",
        dialogDisabled: "disabled:bg-orange-200",
        iconColor: "text-orange-600",
        toolTipContent: "text-orange-800",
        toolTipDescription: "text-orange-700",
        toolTipArrow: "fill-orange-100",
      };
    }

    if (statusId === ShowingStatus.FREEENTRY) {
      return {
        bgColor: "bg-green-100",
        dialogBgColor: "bg-green-500",
        dialogCloseHover: "hover:bg-green-400",
        dialogDisabled: "disabled:bg-green-200",
        iconColor: "text-green-600",
        toolTipContent: "text-green-800",
        toolTipDescription: "text-green-700",
        toolTipArrow: "fill-green-100",
      };
    }

    if (statusId === ShowingStatus.AVAILABLE) {
      switch (accessType) {
        case AccessType.SALESCLOSED || AccessType.NOTONSALE:
          return {
            bgColor: "bg-red-100",
            dialogBgColor: "bg-red-500",
            dialogCloseHover: "hover:bg-red-400",
            dialogDisabled: "disabled:bg-red-200",
            iconColor: "text-red-600",
            toolTipContent: "text-red-800",
            toolTipDescription: "text-red-700",
            toolTipArrow: "fill-red-100",
          };
        case AccessType.DOORSALES:
          return {
            bgColor: "bg-orange-100",
            dialogBgColor: "bg-orange-500",
            dialogCloseHover: "hover:bg-orange-400",
            dialogDisabled: "disabled:bg-orange-200",
            iconColor: "text-orange-600",
            toolTipContent: "text-orange-800",
            toolTipDescription: "text-orange-700",
            toolTipArrow: "fill-orange-100",
          };
        case AccessType.NOTYETONSALE:
          return {
            bgColor: "bg-orange-100",
            dialogBgColor: "bg-orange-500",
            dialogCloseHover: "hover:bg-orange-400",
            dialogDisabled: "disabled:bg-orange-200",
            iconColor: "text-orange-600",
            toolTipContent: "text-orange-800",
            toolTipDescription: "text-orange-700",
            toolTipArrow: "fill-orange-100",
          };
        case AccessType.EXPIRED:
          return {
            bgColor: "bg-red-100",
            dialogBgColor: "bg-red-500",
            dialogCloseHover: "hover:bg-red-400",
            dialogDisabled: "disabled:bg-red-200",
            iconColor: "text-red-600",
            toolTipContent: "text-red-800",
            toolTipDescription: "text-red-700",
            toolTipArrow: "fill-red-100",
          };
      }
    }

    return {
      bgColor: "bg-indigo-100",
      iconColor: "text-indigo-600",
      dialogBgColor: "bg-indigo-500",
      dialogCloseHover: "bg-indigo-400",
      dialogDisabled: "bg-indigo-200",
      toolTipContent: "text-indigo-800",
      toolTipDescription: "text-indigo-700",
      toolTipArrow: "fill-indigo-100",
    };
  };

  return windowSize.width && windowSize.width > 640 ? (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger
          aria-label={`${title}-button`}
          className="cursor-default"
        >
          <QuestionMarkCircleIcon
            className={joinClassNames("w-5 h-5", handleColor().iconColor)}
          />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="bottom"
            sideOffset={10}
            className={joinClassNames(
              "relative z-50 w-96 p-4 rounded-lg animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
              handleColor().bgColor,
              handleColor().toolTipContent
            )}
          >
            <p
              className={joinClassNames(
                "text-sm",
                handleColor().toolTipContent
              )}
            >
              {description}
            </p>
            <Tooltip.Arrow className={handleColor().toolTipArrow} />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  ) : (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <QuestionMarkCircleIcon
          className={joinClassNames("w-5 h-5", handleColor().iconColor)}
        />
      </DialogTrigger>
      <AnimatePresence mode="wait">
        {open && (
          <DialogContent
            asChild
            forceMount
            handleClose={() => setOpen(false)}
            className={joinClassNames(
              "bottom-4 !m-2 !rounded-3xl !p-0 !bg-white !w-[90dvw] max-h-[95dvh] !border-none",
              `!${handleColor().toolTipContent}`
            )}
          >
            <motion.div
              initial="hidden"
              animate="show"
              exit="hidden"
              className="flex flex-col"
              transition={{ duration: 0.2 }}
              variants={{
                hidden: {
                  y: 200,
                  opacity: 0,
                },
                show: {
                  y: 0,
                  opacity: 1,
                },
              }}
            >
              <div
                className={joinClassNames(
                  "relative px-6 pt-6 pb-4 flex flex-col gap-4 justify-between flex-1 rounded-t-3xl",
                  handleColor().dialogBgColor
                )}
              >
                <div className="relative self-end w-8 h-8 rounded-full bg-white/10">
                  <DialogClose
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="absolute top-2 left-2 w-4 h-4 text-white !font-bold"
                  >
                    <XMarkIcon />
                  </DialogClose>
                </div>
                <DialogHeader className="!text-left">
                  <DialogTitle className="!text-white text-xl !font-bold">
                    {title}
                  </DialogTitle>
                  <ClockIcon className="absolute top-0 left-4 w-24 h-24 text-white/10 -rotate-12" />
                </DialogHeader>
              </div>
              <div className="px-6 text-gray-600">{description}</div>
              <div className="flex-1 flex items-center justify-center px-6 pb-6">
                <DialogClose
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={joinClassNames(
                    "flex items-center justify-center w-full p-2 rounded-md text-white font-semibold",
                    handleColor().dialogBgColor,
                    handleColor().dialogCloseHover,
                    handleColor().dialogDisabled
                  )}
                >
                  Done
                </DialogClose>
              </div>
            </motion.div>
          </DialogContent>
        )}
      </AnimatePresence>
    </Dialog>
  );
};

export default ShowingStatusToolTip;
