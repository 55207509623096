import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/popovers/dialog";
import {
  BuildingStorefrontIcon,
  XMarkIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";
import { Outlets } from "@types";
import { joinClassNames } from "@utils/helpers";
import { useHydrated, useWindowSize } from "@utils/hooks";
import { AnimatePresence, Variants, motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";

interface Props {
  /**
   * takes in outlets as props that are available to buy tickets from
   */
  outlets: Outlets[];
}

const Outlet = ({ outlets }: Props) => {
  const windowSize = useWindowSize();
  const [open, setOpen] = useState(false);
  const hydrated = useHydrated();
  const isIOS = hydrated ? navigator.userAgent.includes("like Mac") : false;

  const variants: Variants =
    windowSize.width && windowSize.width > 1024
      ? {
          hidden: {
            opacity: 0,
            translateY: "-50%",
            scale: 0.9,
            transition: { duration: 0.125 },
          },
          show: {
            opacity: 1,
            translateY: "-50%",
            scale: 1,
          },
        }
      : {
          hidden: {
            y: 200,
            opacity: 0,
          },
          show: {
            y: 0,
            opacity: 1,
          },
        };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="flex gap-1 items-center px-2 py-1 w-fit rounded-lg bg-indigo-100 text-indigo-800 hover:bg-indigo-500 hover:text-white transition-colors ease-in-out duration-200">
        <BuildingStorefrontIcon className="h-5 w-5" aria-hidden="true" />
        <h2 className="text-base font-semibold">Outlet Tickets Available</h2>
      </DialogTrigger>
      <AnimatePresence>
        {open && (
          <DialogContent
            asChild
            forceMount
            handleClose={() => setOpen(false)}
            className={joinClassNames(
              "!text-indigo-800 !border-none",
              windowSize.width && windowSize.width > 1024
                ? "!rounded-3xl top-1/2 -translate-y-1/2 !max-w-none !w-fit !p-0 !bg-white"
                : "bottom-4 !m-2 !rounded-3xl !p-0 !bg-white !max-w-[90dvw] max-h-[95dvh]"
            )}
          >
            <motion.div
              initial="hidden"
              animate="show"
              exit="hidden"
              variants={variants}
              transition={{ duration: 0.25 }}
              className="flex flex-col !gap-0"
            >
              <div className="relative px-6 pt-6 pb-4 flex flex-col gap-4 justify-between flex-1 bg-indigo-500 rounded-t-3xl">
                <div className="relative self-end w-8 h-8 rounded-full bg-white/10">
                  <DialogClose className="absolute top-2 left-2 w-4 h-4 text-white !font-bold">
                    <XMarkIcon />
                  </DialogClose>
                </div>
                <DialogHeader className="!text-left">
                  <DialogTitle className="!text-white text-xl !font-bold">
                    Outlets
                  </DialogTitle>
                  <BuildingStorefrontIcon className="absolute top-0 left-4 w-24 h-24 text-white/10 -rotate-12" />
                </DialogHeader>
              </div>
              <ul className="flex flex-col items-start gap-4 px-6 py-4 text-gray-600">
                {outlets.map((outlet) => (
                  <li
                    key={outlet.id}
                    className="flex flex-col md:flex-row items-center gap-y-4 md:gap-y-0 md:gap-x-4"
                  >
                    <Image
                      src={outlet.logo.trim()}
                      width={150}
                      height={100}
                      className="rounded-md object-cover"
                      alt="outlet store"
                    />
                    <div className="flex flex-col gap-2">
                      <h3 className="font-semibold text-lg">{outlet.name}</h3>
                      <Link
                        href={`${
                          isIOS ? "maps" : "https"
                        }://maps.google.com/maps/search/${outlet.name},${
                          outlet.address
                        },${outlet.region}`}
                        className="flex w-fit items-center gap-1 px-2 py-1 text-sm leading-6 font-medium rounded-lg bg-indigo-100 text-indigo-800 hover:bg-indigo-500 hover:text-white transition-colors ease-in-out duration-200"
                      >
                        <MapPinIcon className="w-5 h-5" />
                        <span>
                          {outlet.address}, {outlet.region}
                        </span>
                      </Link>
                      <p
                        className="prose text-sm text-gray-500"
                        dangerouslySetInnerHTML={{ __html: outlet.hours }}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </motion.div>
          </DialogContent>
        )}
      </AnimatePresence>
    </Dialog>
  );
};

export default Outlet;
