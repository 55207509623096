import LoadingSpinner from "@components/loadingSpinner";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  SparklesIcon,
  XMarkIcon as XMarkIconSmall,
} from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PriceStatus } from "@utils/enums";
import { joinClassNames } from "@utils/helpers";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import * as Select from "@radix-ui/react-select";
import { FormattedPrice } from "../types";

interface Props {
  price: FormattedPrice;
  putQtyInCart: (
    id: string,
    type: string,
    priceAgeId: number | null,
    priceSectionId: number | null,
    priceId: number | null,
    gaAreaId: number | null,
    qty: string,
    isCombo: boolean,
    price: number,
    comboId: number | null,
    isDynamic?: boolean
  ) => Promise<"success" | "failure">;
  selectedQuantities: { id: string; qty: number; qty_from_combo: number }[];
}

export default function AddTicketDropdown({
  price,
  putQtyInCart,
  selectedQuantities,
}: Props) {
  const [priceLoading, setPriceLoading] = useState<{
    id: number;
    status: "loading" | "success" | "failure";
  } | null>(null);
  const selectedQty = selectedQuantities.find((sq) => sq.id === price.id);

  const option_array = [
    0,
    ...Array.from(
      {
        length:
          (price.bookingLimit ?? price.max) -
          price.min +
          (price.min === 0 ? 0 : 1) +
          (price.bookingLimit && price.bookingLimit < price.max && selectedQty
            ? selectedQty.qty
            : 0),
      },
      (_, index) => price.min + index + (price.min === 0 ? 1 : 0)
    ),
  ];

  return (
    <div className="relative w-16">
      <Select.Root
        value={selectedQty?.qty.toString() ?? ""}
        onValueChange={async (qty) => {
          setPriceLoading({
            id: price.priceAgeId
              ? price.priceAgeId
              : price.comboId
              ? price.comboId
              : 0,
            status: "loading",
          });
          const result = await putQtyInCart(
            price.id,
            price.type,
            price.priceAgeId,
            price.priceSectionId,
            price.priceId,
            price.gaAreaId,
            qty,
            price.isCombo,
            price.price,
            price.comboId ?? 0,
            false
          );
          setPriceLoading({
            id: price.priceAgeId
              ? price.priceAgeId
              : price.comboId
              ? price.comboId
              : 0,
            status: result,
          });
          setTimeout(() => setPriceLoading(null), 1000);
        }}
        disabled={
          (price.priceAgeId && priceLoading?.id === price.priceAgeId) ||
          (price.comboId && priceLoading?.id === price.comboId) ||
          (price.isCombo
            ? price.max === 0 || price.max === price.bookedTickets
            : (price.status !== PriceStatus.AVAILABLE &&
                selectedQty?.qty === 0) ||
              (price.bookingLimit ?? price.max) < price.min)
        }
      >
        <Select.Trigger
          data-testid={`selectticketqty-${price.priceAgeId}`}
          className={joinClassNames(
            "relative px-4 text-sm leading-none text-black border rounded shadow-sm cursor-default disabled:cursor-not-allowed w-full h-9 focus:outline-none focus:ring-1 focus:ring-indigo-500 transition-colors ease-in-out duration-200",
            (price.priceAgeId && priceLoading?.id === price.priceAgeId) ||
              (price.comboId && priceLoading?.id === price.comboId)
              ? priceLoading?.status === "loading"
                ? "bg-gray-100 border-gray-300"
                : priceLoading?.status === "success"
                ? "bg-emerald-100 border-emerald-400"
                : "bg-red-100 border-red-400"
              : "bg-white border-gray-300 hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-500 focus:border-indigo-500"
          )}
        >
          <AnimatePresence mode="popLayout" initial={false}>
            <motion.div
              className="inline-flex items-center justify-center gap-1"
              key={
                (price.priceAgeId && priceLoading?.id === price.priceAgeId) ||
                (price.comboId && priceLoading?.id === price.comboId)
                  ? priceLoading?.status
                  : "quantity"
              }
              initial={{ opacity: 0, width: "auto" }}
              animate={{ opacity: 1, width: "auto" }}
              exit={{ opacity: 0, width: "auto" }}
              transition={{
                duration: 0.15,
                delay: 0.15,
              }}
            >
              {(price.priceAgeId && priceLoading?.id === price.priceAgeId) ||
              (price.comboId && priceLoading?.id === price.comboId) ? (
                priceLoading?.status === "loading" ? (
                  <LoadingSpinner
                    data-testid="priceloading"
                    size="16"
                    classes="w-7"
                  />
                ) : priceLoading?.status === "success" ? (
                  <CheckIcon className="w-7 h-4 text-emerald-800" />
                ) : (
                  <XMarkIcon className="w-7 h-4 text-red-800" />
                )
              ) : (
                <>
                  <Select.Value>{selectedQty?.qty.toString()}</Select.Value>
                  <Select.Icon>
                    <ChevronDownIcon className="w-4 h-4" />
                  </Select.Icon>
                </>
              )}
            </motion.div>
          </AnimatePresence>
          <motion.div
            className="absolute -top-2 -right-2"
            animate={
              ((price.priceAgeId && priceLoading?.id === price.priceAgeId) ||
                (price.comboId && priceLoading?.id === price.comboId)) &&
              priceLoading?.status !== "loading"
                ? { scale: [0, 1, 1.2, 1, 0], rotate: [0, 2, 4, 6, 8] }
                : { scale: 0, rotate: 0 }
            }
            transition={{ duration: 1.5 }}
          >
            {priceLoading?.status === "success" ? (
              <SparklesIcon className="text-yellow-400 h-4 w-4" />
            ) : priceLoading?.status === "failure" ? (
              <XMarkIconSmall className="text-red-400 h-4 w-4" />
            ) : (
              <></>
            )}
          </motion.div>
          <motion.div
            className="absolute bottom-0 -left-3"
            animate={
              ((price.priceAgeId && priceLoading?.id === price.priceAgeId) ||
                (price.comboId && priceLoading?.id === price.comboId)) &&
              priceLoading?.status !== "loading"
                ? { scale: [0, 1, 1.2, 1, 0], rotate: [0, -3, -6, -9, -12] }
                : { scale: 0, rotate: 0 }
            }
            transition={{ duration: 1.5 }}
          >
            {priceLoading?.status === "success" ? (
              <SparklesIcon className="text-yellow-400 h-4 w-4" />
            ) : priceLoading?.status === "failure" ? (
              <XMarkIconSmall className="text-red-400 h-4 w-4" />
            ) : (
              <></>
            )}
          </motion.div>
          {priceLoading &&
          priceLoading.status !== "loading" &&
          ((price.priceAgeId && priceLoading?.id === price.priceAgeId) ||
            (price.comboId && priceLoading?.id === price.comboId)) ? (
            <>
              <motion.div
                className={joinClassNames(
                  "absolute -top-2 -left-4 w-2.5 h-2.5 rounded-[3px]",
                  priceLoading.status === "success"
                    ? "bg-emerald-200"
                    : "bg-red-200"
                )}
                initial={{ x: 0, y: 0, scale: 0, rotate: 0 }}
                animate={{
                  x: [0, -2, -4, -5, -5],
                  y: [0, -2, -4, -5, -5],
                  scale: [0, 1, 1.2, 1, 0],
                  rotate: [0, -3, -6, -9, -12],
                }}
                transition={{ duration: 1.5 }}
              />
              <motion.div
                className={joinClassNames(
                  "absolute -bottom-2 right-2 w-2.5 h-2.5 rounded-[3px]",
                  priceLoading.status === "success"
                    ? "bg-emerald-700"
                    : "bg-red-700"
                )}
                initial={{ x: 0, y: 0, scale: 0, rotate: 0 }}
                animate={{
                  x: [0, 2, 4, 5, 5],
                  y: [0, 4, 8, 11, 11],
                  scale: [0, 1, 1.2, 1, 0],
                  rotate: [0, 3, 6, 9, 12],
                }}
                transition={{ duration: 1.5 }}
              />
              <motion.div
                className={joinClassNames(
                  "absolute -top-2 right-2 w-2 h-2 rounded",
                  priceLoading.status === "success"
                    ? "bg-emerald-100"
                    : "bg-red-100"
                )}
                initial={{ x: 0, y: 0, scale: 0, rotate: 0 }}
                animate={{
                  x: [0, 2, 4, 5, 5],
                  y: [0, -3, -6, -8, -8],
                  scale: [0, 1, 1.2, 1, 0],
                  rotate: [0, 3, 6, 9, 12],
                }}
                transition={{ duration: 1.5 }}
              />
            </>
          ) : (
            <></>
          )}
        </Select.Trigger>
        <Select.Portal>
          <Select.Content
            data-testid={`ticketcontentqty-${price.priceAgeId}`}
            className="relative z-50 overflow-hidden bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <Select.ScrollUpButton className="flex items-center justify-center h-6 text-black cursor-default">
              <ChevronUpIcon className="w-5 h-5" />
            </Select.ScrollUpButton>
            <Select.Viewport className="p-1.5">
              {option_array.map((qty, index) => (
                <Select.Item
                  data-testid={`${index}`}
                  key={qty.toString()}
                  value={qty.toString()}
                  className="relative flex items-center h-6 pl-6 text-sm leading-none text-black rounded select-none pr-9 focus:outline-none focus:bg-indigo-500 focus:text-indigo-50"
                >
                  <Select.ItemText>{qty}</Select.ItemText>
                  <Select.ItemIndicator className="absolute left-0 w-6 cursor-default">
                    <CheckIcon className="w-4 h-4" />
                  </Select.ItemIndicator>
                </Select.Item>
              ))}
            </Select.Viewport>
            <Select.ScrollDownButton className="flex items-center justify-center h-6 text-black cursor-default">
              <ChevronDownIcon className="w-5 h-5" />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
      {!(selectedQty?.id || "").includes("combo") &&
      (selectedQty?.qty_from_combo || 0) > 0 ? (
        <span className="absolute -right-0.5 sm:-right-2 top-full sm:top-[calc(100%+3px)] block w-max px-1 rounded-lg bg-indigo-100 text-indigo-800 text-xs text-center">
          +{selectedQty?.qty_from_combo.toString()} from combo
        </span>
      ) : (
        <></>
      )}
    </div>
  );
}
