import { Slider } from "@components/inputs";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/popovers/dialog";
import {
  ArrowTrendingUpIcon,
  SparklesIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  XMarkIcon as XMarkIconOutline,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { getCurrencyFormat, joinClassNames } from "@utils/helpers";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { FormattedPrice } from "../types";
import LoadingSpinner from "@components/loadingSpinner";
import { ShoppingCartIcon } from "@heroicons/react/20/solid";
import { PriceStatus } from "@utils/enums";

interface Props {
  /**
   * Formatted Price
   */
  price: FormattedPrice;

  /**
   * Loading state when buying tickets
   */
  priceLoading: {
    id: number;
    status: "loading" | "success" | "failure";
  } | null;

  /**
   * quantity of the current ticket bought
   */
  quantity?: string;
  /**
   * quantity of the previous ticket bought
   */
  prevQuantity?: string;
  /**
   * Handle the selection of options
   * @param option
   * @returns
   */
  onOptionSelect: (option: string, price: number) => void;

  /**
   * Options for the dropdown. It can either be an array of strings or numbers
   */
  options: string[] | number[];
}

const MobileFlexi = ({
  quantity,
  prevQuantity,
  onOptionSelect,
  options,
  price,
  priceLoading,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [dynamicPrice, setDynamicPrice] = useState(price.price);
  const [selected, setSelected] = useState("0");
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerScrolled, setContainerScrolled] = useState(
    !Boolean(price.info)
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const scrollHandler = (event: any) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      setContainerScrolled(
        Math.abs(scrollHeight - clientHeight - scrollTop) < 1
      );
    };

    container.addEventListener("scroll", scrollHandler);

    return () => {
      container.removeEventListener("scroll", scrollHandler);
    };
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <motion.button
          className={joinClassNames(
            "relative flex flex-col gap-2 w-full px-4 py-2 ring-1 ring-inset ring-gray-200 shadow rounded-xl",
            priceLoading?.status === "loading" ? "overflow-hidden" : "",
            price.priceAgeId &&
              priceLoading?.id === price.priceAgeId &&
              priceLoading.status === "loading"
              ? "bg-gray-100"
              : "bg-white"
          )}
          disabled={
            price.status !== PriceStatus.AVAILABLE &&
            parseInt(quantity || "0") === 0
          }
          whileTap={{
            scale: 0.95,
          }}
        >
          <AnimatePresence>
            {priceLoading?.status === "loading" && (
              <motion.div
                className="absolute top-0 left-0 w-[200%] h-full"
                style={{
                  backgroundImage: `repeating-linear-gradient( 105deg,#9ca3af44,
                        #9ca3af44 7%,
                        #4b556344 7%,
                        #4b556344 10%
                      )`,
                }}
                initial={{ opacity: 0 }}
                animate={{ x: ["0%", "-50%"], opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  ease: "linear",
                  duration: 3,
                  opacity: {
                    duration: 0.1,
                  },
                }}
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {(priceLoading?.status === "success" ||
              priceLoading?.status === "failure") &&
              quantity && (
                <motion.div
                  className={joinClassNames(
                    "absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full rounded-xl",
                    priceLoading.status === "success"
                      ? "bg-green-100"
                      : "bg-red-100"
                  )}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {parseInt(quantity) - parseInt(prevQuantity || "0") > 0 &&
                  priceLoading.status === "success" ? (
                    <div className="flex items-center gap-2 text-green-800">
                      <ShoppingCartIcon className="h-7 w-7" />
                      <span className="text-lg font-semibold">
                        Added to cart
                      </span>
                    </div>
                  ) : parseInt(quantity) - parseInt(prevQuantity || "0") < 0 &&
                    priceLoading.status === "success" ? (
                    <div className="flex items-center gap-2 text-green-800">
                      <ShoppingCartIcon className="h-7 w-7" />
                      <span className="text-lg font-semibold">
                        Removed from cart
                      </span>
                    </div>
                  ) : (
                    <span className="text-lg font-semibold text-red-800">
                      Something went wrong
                    </span>
                  )}
                </motion.div>
              )}
          </AnimatePresence>
          <div className="flex flex-row items-center justify-between gap-2 w-full">
            <span className="text-left text-lg font-semibold">
              {price.type}
            </span>
            <span className="flex items-center bg-iticket text-white text-xs italic font-semibold rounded-md px-2 py-1 -mr-2 shrink-0">
              {getCurrencyFormat(price.priceMinimum)} -{" "}
              {getCurrencyFormat(price.priceMaximum)}
            </span>
          </div>
          <div className="flex items-center gap-2 flex-wrap">
            <span className="bg-iticket italic font-semibold text-xs text-white rounded-md px-2 py-0.5 w-fit">
              FLEXi
            </span>
            {price.areaName ? (
              <span className="px-2 py-0.5 rounded-md w-fit text-xs bg-sky-100 text-sky-800 font-semibold">
                {price.areaName}
              </span>
            ) : (
              <></>
            )}
            {price.priceSchemeName ? (
              <span className="px-2 py-0.5 rounded-md w-fit text-xs bg-cyan-100 text-cyan-800 font-semibold">
                {price.priceSchemeName}
              </span>
            ) : (
              <></>
            )}
            {price.priceSectionName ? (
              <span className="px-2 py-0.5 rounded-md w-fit text-xs bg-teal-100 text-teal-800 font-semibold">
                {price.priceSectionName}
              </span>
            ) : (
              <></>
            )}
          </div>
          <div
            className={joinClassNames(
              "absolute -top-2 -right-1.5 flex items-center gap-1 flex-wrap justify-end transition-opacity duration-500 ease-in-out ",
              priceLoading !== null ? "opacity-0" : "opacity-100"
            )}
          >
            <span
              className={joinClassNames(
                "flex items-center gap-1 rounded-lg px-2 py-0.5 bg-indigo-100 text-indigo-800 text-xs",
                quantity === "0" ? "hidden" : ""
              )}
            >
              {quantity} in cart
            </span>
          </div>
          <motion.div
            className="absolute -top-2 -right-1 z-20"
            animate={
              price.priceAgeId &&
              priceLoading?.id === price.priceAgeId &&
              priceLoading?.status !== "loading"
                ? { scale: [0, 1, 1.2, 1, 0], rotate: [0, -3, -6, -9, -12] }
                : { scale: 0, rotate: 0 }
            }
            transition={{ duration: 1.5 }}
          >
            {priceLoading?.status === "success" ? (
              <SparklesIcon className="text-yellow-400 h-6 w-6" />
            ) : priceLoading?.status === "failure" ? (
              <XMarkIcon className="text-red-400 h-6 w-6" />
            ) : (
              <></>
            )}
          </motion.div>
          <motion.div
            className="absolute -bottom-2 -left-1 z-20"
            animate={
              price.priceAgeId &&
              priceLoading?.id === price.priceAgeId &&
              priceLoading?.status !== "loading"
                ? { scale: [0, 1, 1.2, 1, 0], rotate: [0, -3, -6, -9, -12] }
                : { scale: 0, rotate: 0 }
            }
            transition={{ duration: 1.5 }}
          >
            {priceLoading?.status === "success" ? (
              <SparklesIcon className="text-yellow-400 h-6 w-6" />
            ) : priceLoading?.status === "failure" ? (
              <XMarkIcon className="text-red-400 h-6 w-6" />
            ) : (
              <></>
            )}
          </motion.div>
          {priceLoading &&
          priceLoading.status !== "loading" &&
          price.priceAgeId &&
          priceLoading.id === price.priceAgeId ? (
            <>
              <motion.div
                className={joinClassNames(
                  "absolute -top-2 left-4 w-2.5 h-2.5 rounded-[2px] z-20",
                  priceLoading.status === "success"
                    ? "bg-emerald-200"
                    : "bg-red-200"
                )}
                initial={{ x: 0, y: 0, opacity: 0, rotate: 0 }}
                animate={{
                  x: [0, -4, -8, -14, -16, -18],
                  y: [0, -8, -10, -9, -5, 10],
                  opacity: [0, 1, 1, 1, 1, 0],
                  rotate: [-1, -3, -6, -9, -12],
                }}
                transition={{ duration: 1, delay: 0.1 }}
              />

              <motion.div
                className={joinClassNames(
                  "absolute -top-2 left-24 w-3.5 h-3.5 rounded z-20",
                  priceLoading.status === "success"
                    ? "bg-emerald-500"
                    : "bg-red-500"
                )}
                initial={{ x: 0, y: 0, scale: 0, rotate: 0 }}
                animate={{
                  x: [0, -2, -4, -5, -5],
                  y: [0, -2, -4, -5, -5],
                  scale: [0, 1, 1.2, 1, 0],
                  rotate: [0, -3, -6, -9, -12],
                }}
                transition={{ duration: 1.2 }}
              />

              <motion.div
                className={joinClassNames(
                  "absolute -top-4 right-20 w-5 h-5 rounded-[3px] z-20",
                  priceLoading.status === "success"
                    ? "bg-emerald-200"
                    : "bg-red-700"
                )}
                initial={{ x: 0, y: 0, scale: 0, rotate: 0 }}
                animate={{
                  x: [0, 2, 4, 5, 5],
                  y: [0, -2, -4, -5, -7, -7],
                  scale: [0, 1, 1.2, 1, 0],
                  rotate: [1, 6, 12, 18, 24],
                }}
                transition={{ duration: 1.5, delay: 0.2 }}
              />

              <motion.div
                className={joinClassNames(
                  "absolute -bottom-1 right-1/2 w-3.5 h-3.5 rounded-[3px] z-20",
                  priceLoading.status === "success"
                    ? "bg-emerald-500"
                    : "bg-red-500"
                )}
                initial={{ x: 0, y: 0, scale: 0, rotate: 0 }}
                animate={{
                  x: [0, -2, -4, -5, -5],
                  y: [0, 2, 4, 5, 7, 7],
                  scale: [0, 1, 1.2, 0.8, 0.4, 0],
                  rotate: [0, -3, -6, -9, -12],
                }}
                transition={{ duration: 1.5 }}
              />

              <motion.div
                className={joinClassNames(
                  "absolute -bottom-2 right-4 w-4 h-4 rounded-[3px] z-20",
                  priceLoading.status === "success"
                    ? "bg-emerald-500"
                    : "bg-red-500"
                )}
                initial={{ x: 0, y: 0, scale: 0, rotate: 0 }}
                animate={{
                  x: [0, 1, 2, 3, 3],
                  y: [0, 2, 4, 5, 5],
                  scale: [0, 1, 1.2, 1, 0],
                  rotate: [0, -3, -6, -9, -12],
                }}
                transition={{ duration: 1.5 }}
              />
            </>
          ) : (
            <></>
          )}
        </motion.button>
      </DialogTrigger>
      <AnimatePresence>
        {open && (
          <DialogContent
            asChild
            forceMount
            handleClose={() => setOpen(false)}
            className="bottom-4 !gap-0 !m-2 !p-0 !rounded-3xl !bg-iticket !max-w-[90dvw] max-h-[75dvh] !text-indigo-800 !border-none"
          >
            <motion.div
              initial="hidden"
              animate="show"
              exit="hidden"
              className="flex flex-col w-full overflow-hidden"
              transition={{ duration: 0.2 }}
              variants={{
                hidden: {
                  y: 200,
                  opacity: 0,
                },
                show: {
                  y: 0,
                  opacity: 1,
                },
              }}
            >
              <div
                className={joinClassNames(
                  "relative px-6 pt-6 flex flex-col gap-3 justify-between flex-1 bg-iticket rounded-t-3xl",
                  !price.info ? "pb-2" : ""
                )}
              >
                <div className="relative self-end w-8 h-8 rounded-full bg-white/10">
                  <DialogClose className="absolute top-2 left-2 w-4 h-4 text-white !font-bold">
                    <XMarkIconOutline />
                  </DialogClose>
                </div>
                <DialogHeader className="!text-left">
                  <DialogTitle className="!text-white text-xl !font-bold">
                    {price.type}
                  </DialogTitle>
                  <ArrowTrendingUpIcon className="absolute top-0 left-4 w-24 h-24 text-white/10 -rotate-12" />
                </DialogHeader>
                {price.info ? (
                  <span className="absolute top-full inset-x-0 w-full h-4 bg-gradient-to-b from-iticket" />
                ) : (
                  <></>
                )}
              </div>
              <div ref={containerRef} className="overflow-auto">
                {price.info ? (
                  <div className="bg-iticket text-white/75 px-6 py-2">
                    {price.info}
                  </div>
                ) : (
                  <></>
                )}
                <div className="flex flex-col items-center gap-2 w-full px-6 bg-white">
                  <span className="my-4 text-7xl font-black text-iticket">
                    {getCurrencyFormat(
                      dynamicPrice ? dynamicPrice : price.price
                    )}
                  </span>
                  <Slider
                    defaultValue={[dynamicPrice ? dynamicPrice : price.price]}
                    max={price.priceMaximum}
                    min={price.priceMinimum}
                    step={1}
                    onValueChange={(val) => {
                      setDynamicPrice(val[0]);
                    }}
                  />
                  <div className="flex items-center justify-between w-full text-black">
                    <span>{getCurrencyFormat(price.priceMinimum)}</span>
                    <span>{getCurrencyFormat(price.priceMaximum)}</span>
                  </div>
                </div>
                <div
                  className={joinClassNames(
                    "max-h-[calc(75dvh-108px-150px-76px)] bg-white",
                    containerScrolled ? "overflow-y-auto" : "overflow-hidden"
                  )}
                >
                  {options.map((option) => (
                    <button
                      onClick={() => {
                        containerRef.current?.scrollTo({
                          top: containerRef.current.scrollHeight,
                          behavior: "smooth",
                        });
                        setSelected(option.toString());
                      }}
                      key={option}
                      className="flex flex-col items-center w-full"
                    >
                      <div className="relative flex items-center">
                        {option.toString() === selected ? (
                          <CheckIcon className="absolute right-[calc(100%-1rem)] top-1.5 stroke-[3] text-iticket w-7 h-7" />
                        ) : (
                          <></>
                        )}
                        <div
                          className={joinClassNames(
                            "py-2 px-6 w-fit text-2xl font-black",
                            option.toString() === selected
                              ? "text-iticket"
                              : "text-black"
                          )}
                        >
                          {option}
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
                <div className="px-6 pb-6 pt-3 bg-white">
                  <motion.button
                    whileTap={{
                      scale: 0.95,
                    }}
                    onClick={async () => {
                      if (selected) {
                        onOptionSelect(selected, dynamicPrice);
                        setSelected("0");
                        setOpen(false);
                      }
                    }}
                    disabled={!!priceLoading || selected === "0"}
                    className="flex items-center justify-center gap-2 w-full p-2 rounded-md bg-iticket hover:bg-iticket/90 disabled:bg-iticket/60 text-white font-semibold transition-colors duration-200 ease-in-out"
                  >
                    {!!priceLoading ? (
                      <>
                        <LoadingSpinner size="20" color="#ffffff" />
                        <span>Adding to cart...</span>
                      </>
                    ) : (
                      <>
                        <ShoppingCartIcon className="w-5 h-5" />
                        <span>Add to cart</span>
                      </>
                    )}
                  </motion.button>
                </div>
              </div>
              <span className="absolute bottom-0 inset-x-0 w-full h-4 bg-gradient-to-t from-white" />
            </motion.div>
          </DialogContent>
        )}
      </AnimatePresence>
    </Dialog>
  );
};

export default MobileFlexi;
